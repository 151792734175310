/* eslint-disable no-unused-vars */
import React from "react";
import useState from "react-usestateref";
import ContentList from "./Content";
import NewContent from "./NewContent";
import Tabs from "../../helpers/tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default () => {
  const [showSignin, setShowSignin] = useState(false);
  const [edit, setEdit] = useState(false);
  const [load, setLoad] = useState(false);

  const [id, setID] = useState("");
  const [editData, setEditData, editDataRef] = useState({
    name: "",
    description: "",
    category: "",
    categoryLevel: "",
    message: "",
  });

  const resetState = () => {
    setEditData({
      name: "",
      description: "",
      category: "",
      categoryLevel: "",
      message: "",
    });
    setEdit(false);
  };

  const handleEditData = (data, id) => {
    setEditData({ ...data });
    setID(id);
    setEdit(true);
    setShowSignin(true);
  };

  const data = [
    {
      route: "All",
      component: () => (
        <ContentList load={load} handleEditData={handleEditData} />
      ),
    },
    {
      route: <FontAwesomeIcon icon={faPlus} className="me-2" />,
      component: () => (
        <NewContent
          edit={edit}
          resetState={resetState}
          id={id}
          setEdit={setEdit}
          editData={editDataRef.current}
          showSignin={showSignin}
          setShowSignin={setShowSignin}
        />
      ),
    },
  ];
  return <Tabs data={data} load={load} setLoad={setLoad} />;
};
