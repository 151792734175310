/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
	faEnvelope,
	faUnlockAlt,
	faUser,
	faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	FormCheck,
	InputGroup,
	Row,
	Spinner,
} from '@themesberg/react-bootstrap';
import axios from 'axios';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useState from 'react-usestateref';
import BgImage from '../../assets/img/illustrations/signin.svg';

export default () => {
	let history = useHistory();
	const [data, setData, dataRef] = useState({
		userName: '',
		password: '',
	});
	const [dataError, setDataError, dataErrorRef] = useState({
		userName: '',
		password: '',
	});
	const [loading, setLoading] = useState(false);
	const [msg, setMsg] = useState('');
	const [open, setOpen] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const onChangeInput = (event) => {
		setData({ ...data, [event.target.name]: event.target.value });
	};

	// show password
	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const checkForm = async () => {
		if (!dataRef.current.password) {
			setDataError({
				...dataErrorRef.current,
				password: 'Please provide your password',
			});
		} else {
			setDataError({ ...dataErrorRef.current, password: '' });
		}

		if (!dataRef.current.userName) {
			setDataError({
				...dataErrorRef.current,
				userName: 'Please provide your username',
			});
		} else {
			setDataError({ ...dataErrorRef.current, userName: '' });
		}
	};

	const login = async (e) => {
		e.preventDefault();
		await checkForm();

		if (dataErrorRef.current.userName || dataErrorRef.current.password) {
			return;
		}

		setLoading(true);
		axios
			.post(`${process.env.REACT_APP_BACKEND_API}/v1/auth/login`, {
				...dataRef.current,
			})
			.then((res) => {
				const { user, accessToken, refreshToken } = res.data.results;

				localStorage.setItem('accessToken', accessToken);
				localStorage.setItem('refreshToken', refreshToken);
				localStorage.setItem('user', user);
				localStorage.setItem('userName', user.userName);
				localStorage.setItem('serviceCode', user.serviceCode[0].name);
				localStorage.setItem('role', user.role);
				toast.success('Logged in');
				history.push(
					user?.isAfcon === true ? '/afcon/2024/transactions' : '/transactions'
				);
				setLoading(false);
			})
			.catch((err) => {
				let error = '';
				if (err && !err.response) {
					error = 'Something wrong happened during the request';
				} else {
					error = 'Incorrect username or password';
				}

				setMsg(error);
				toast.error(error);
				setOpen(true);
				setLoading(false);
			});
	};

	return (
		<main>
			<section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
				<Container>
					<Row
						className="justify-content-center form-bg-image"
						style={{ backgroundImage: `url(${BgImage})` }}
					>
						<Col
							xs={12}
							className="d-flex align-items-center justify-content-center"
						>
							<div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
								<div className="text-center text-md-center mb-4 mt-md-0">
									<h3 className="mb-0">Sign in to Tabz media</h3>
								</div>
								<Form className="mt-4" onSubmit={login}>
									<Form.Group className="mb-4">
										<Form.Label>Your Username</Form.Label>
										<InputGroup>
											<InputGroup.Text>
												<FontAwesomeIcon icon={faUser} />
											</InputGroup.Text>
											<Form.Control
												autoFocus
												name="userName"
												placeholder="Username"
												onChange={onChangeInput}
												value={data.userName}
												isInvalid={dataError.userName}
											/>
										</InputGroup>
									</Form.Group>

									<Form.Group>
										<Form.Group id="password" className="mb-3">
											<Form.Label>Your Password</Form.Label>
											<InputGroup className="mr-4">
												<InputGroup.Text>
													<FontAwesomeIcon icon={faUnlockAlt} />
												</InputGroup.Text>
												<Form.Control
													type={showPassword ? 'text' : 'password'}
													name="password"
													placeholder="Password"
													onChange={onChangeInput}
													value={data.password}
													isInvalid={dataError.password}
												/>
											</InputGroup>
										</Form.Group>
										<div className="d-flex justify-content-between align-items-center mb-4">
											<Form.Check type="checkbox">
												<FormCheck.Input
													onChange={handleShowPassword}
													id="showpasscheck"
													className="me-2"
												/>
												<FormCheck.Label
													htmlFor="showpasscheck"
													className="mb-0"
												>
													Show password
												</FormCheck.Label>
											</Form.Check>
										</div>
									</Form.Group>
									<Button
										className="w-100 me-1 shadow-none"
										variant="primary"
										type="submit"
										disabled={loading}
									>
										<span className="ms-1">Sign in</span>
										{loading ? (
											<Spinner animation="border" size="sm" className="ml-5" />
										) : (
											''
										)}
									</Button>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</main>
	);
};
