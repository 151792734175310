/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@themesberg/react-bootstrap';
import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import useState from 'react-usestateref';
import { FaliledTransactions } from '../../components/Tables';

export default ({ load }) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [page, setPage] = useState(1);

	const [clickedButton, setClickedButton] = useState([]);

	React.useEffect(() => {
		getData();
	}, [page, load, refresh]);

	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.accessToken}`,
		},
	};

	const reloadTransactions = () => {
		setRefresh(!refresh);
	};

	const getData = () => {
		setLoading(true);
		axios
			.get(
				`${
					process.env.REACT_APP_BACKEND_API
				}/v1/transactions/to/give/attention?page=${page}&limit=${50}`,
				config
			)
			.then((res) => {
				setData(res.data.results);
				setLoading(false);
				toast.success('Transactions');
			})
			.catch((err) => {
				setData([]);
				setLoading(false);
				toast.error('Unexpected error');
			});
	};

	const resolveTransaction = (id) => {
		setClickedButton([...clickedButton, id]);
		// setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BACKEND_API}/v1/transactions/to/give/attention/${id}`,
				{ isResolved: true },
				config
			)
			.then((res) => {
				// setData(res.data.results);
				// setLoading(false);
				toast.success('Resolved');
			})
			.catch((err) => {
				console.log(err, '---err=----');
				// setData([]);
				// setLoading(false);
				toast.error('Unexpected error');
			});
	};

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<h4>Failed transactions</h4>
					<h6>
						Clients with failed transactions are engaged to assist them in
						making successful transactions
					</h6>
					<p>
						<span className="text-success">Yes</span>: The client has been
						engaged and helped to perform a successful transaction
						<br /> <span className="text-warning">No </span>: The client needs
						to be engaged
					</p>
					<p className="mb-0">
						<Button
							onClick={reloadTransactions}
							variant="white"
							className="m-1"
							style={{ fontSize: '14px', color: '#CDCCD0' }}
						>
							<i class="fas fa-sync-alt"></i> <span>Refresh</span>
						</Button>
					</p>
				</div>
			</div>

			<FaliledTransactions
				data={data}
				loading={loading}
				setPage={setPage}
				resolveTransaction={resolveTransaction}
				clickedButton={clickedButton}
			/>
		</>
	);
};
