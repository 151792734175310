/* eslint-disable no-unused-vars */
import {
	Button,
	Card,
	Form,
	FormCheck,
	InputGroup,
	Modal,
	Spinner,
} from '@themesberg/react-bootstrap';
import React from 'react';
import useState from 'react-usestateref';
export default ({
	showSignin,
	setShowSignin,
	handleSubmit,
	onChangeInput,
	dataError,
	data,
	loading,
	resetState,
}) => {
	const handleClose = () => {
		setShowSignin(false);
		resetState();
	};

	const [showPassword, setShowPassword] = useState(false);

	// show password
	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	return (
		<React.Fragment>
			{/* {open ? <Alert variant={variant} msg={msg} setOpen={setOpen} /> : ""} */}

			<Modal centered show={showSignin} onHide={handleClose}>
				<Modal.Header className="border-0">
					<Button variant="close" aria-label="Close" onClick={handleClose} />
				</Modal.Header>
				<Modal.Body className="p-0">
					<Card className="px-0 px-md-4 py-0 border-0">
						<Card.Header className="pb-0 py-0 border-0">
							<h2 className="h4">Update user login information</h2>
							<span>User information here</span>
						</Card.Header>
						<Card.Body>
							<Form action="#" className="mt-4" onSubmit={handleSubmit}>
								<Form.Group>
									<Form.Label>Username *</Form.Label>
									<Form.Control
										name="userName"
										type="text"
										onChange={onChangeInput}
										placeholder="Username.."
										isInvalid={dataError.userName}
										value={data.userName}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.userName}
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group>
									<Form.Group id="password" className="mb-4">
										<Form.Label>Password *</Form.Label>
										<InputGroup>
											<Form.Control
												type={showPassword ? 'text' : 'password'}
												name="password"
												placeholder="Password.."
												onChange={onChangeInput}
												isInvalid={dataError.password}
												value={data.password}
											/>
										</InputGroup>
									</Form.Group>
								</Form.Group>
								<div className="d-flex justify-content-between align-items-center mb-4">
									<Form.Check type="checkbox">
										<FormCheck.Input
											onChange={handleShowPassword}
											id="showpasscheck"
											className="me-2"
										/>
										<FormCheck.Label htmlFor="showpasscheck" className="mb-0">
											Show password
										</FormCheck.Label>
									</Form.Check>
								</div>
								<Form.Group id="role">
									<Form.Label>Select user role</Form.Label>
									<fieldset value={data.role}>
										<Form.Check
											defaultChecked
											type="radio"
											defaultValue="agent"
											label="Agent"
											name="role"
											disabled
										/>

										<Form.Check
											disabled
											type="radio"
											defaultValue="option2"
											label="Customer care"
											name="exampleRadios"
											id="radio2"
											htmlFor="radio2"
										/>
									</fieldset>
									<Form.Control.Feedback type="invalid">
										{dataError.role}
									</Form.Control.Feedback>
								</Form.Group>
								<div className="mt-3">
									<Button
										variant="primary"
										type="submit"
										disabled={loading}
										className="shadow-none"
									>
										Save All
										{loading ? <Spinner animation="border" size="sm" /> : ''}
									</Button>
								</div>
							</Form>
						</Card.Body>
					</Card>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
};
