
const Error500 = (error) => {


    let errorStr = '';
    for (var key in error) {
        if (error.hasOwnProperty(key)) {
            errorStr += errorStr.length ? `${' '} ${error[key].message}` : `,${' '} ${error[key].message}`
        }
    }
    return errorStr;

}

export default Error500;
