/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import useState from "react-usestateref";
import axios from "axios";
import Alert from "../../helpers/alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Button } from "@themesberg/react-bootstrap";
import { NewUserForm } from "../../components/Forms";
import Error500 from "../../helpers/500";
import Modal from "./modal";
import { toast } from "react-toastify";

export default ({
  showSignin,
  setShowSignin,
  edit,
  setEdit,
  editData,
  id,
  resetState,
}) => {
  const [data, setData, dataRef] = useState({
    userName: "",
    password: "",
    role: "agent",
  });

  const [dataError, setDataError, dataErrorRef] = useState({
    userName: "",
    password: "",
    role: "agent",
  });

  const [loading, setLoading, loadingRef] = useState(false);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const onChangeInput = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  React.useEffect(() => {
    setData({ ...editData });
  }, [edit]);

  const checkForm = async () => {
    if (!dataRef.current.password) {
      setDataError({
        ...dataErrorRef.current,
        password: "Please provide the password",
      });
    } else {
      if (dataRef.current.password.length < 8) {
        setDataError({
          ...dataErrorRef.current,
          password: "Password must be atleast 8 characters long",
        });
      } else {
        setDataError({ ...dataErrorRef.current, password: "" });
      }
    }

    if (!dataRef.current.userName) {
      setDataError({
        ...dataErrorRef.current,
        userName: "Please provide the userName",
      });
    } else {
      setDataError({ ...dataErrorRef.current, userName: "" });
    }

    if (!dataRef.current.role) {
      setDataError({
        ...dataErrorRef.current,
        role: "Please provide the role",
      });
    } else {
      setDataError({ ...dataErrorRef.current, role: "" });
    }
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await checkForm();
    const me = dataErrorRef.current.password || dataErrorRef.current.userName;
    if (me) {
      return;
    }

    setLoading(true);
    if (!edit) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/v1/auth/create/other`,
          {
            ...dataRef.current,
            role: "agent",
          },
          config
        )
        .then((res) => {
          // setMsg('Successfully created a new user');
          toast.success("Successfully created a new user");
          setOpen(true);
          setIsError(false);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setLoading(false);
            return;
          } else if (err.response.status === 500) {
            // setMsg(Error500(err.response.data.errors[0].errors));
            toast(Error500(err.response.data.errors[0].errors));
            setOpen(true);
            setIsError(true);
            setLoading(false);
            return;
          }

          let error;
          try {
            error = err.response.data.errors[0].error;
          } catch {
            error = "Unable to create a user account";
          }

          // setMsg(error);
          toast.error(error);
          setOpen(true);
          setIsError(true);
          setLoading(false);
        });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_API}/v1/users/${id}`,
          {
            ...dataRef.current,
          },
          config
        )
        .then((res) => {
          // setMsg('Successfully updated a user');
          toast.success("Successfully updated a user");
          setOpen(true);
          setIsError(false);
          setLoading(false);
          setEdit(false);
          resetState();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setLoading(false);
            setEdit(false);
            return;
          } else if (err.response.status === 500) {
            // setMsg(Error500(err.response.data.errors[0].errors));
            toast.success(Error500(err.response.data.errors[0].errors));
            setOpen(true);
            setIsError(true);
            setLoading(false);
            setEdit(false);
            resetState();
            return;
          }

          let error;
          try {
            error = err.response.data.errors[0].error;
          } catch {
            error = "Unable to create a user account";
          }

          // setMsg(error);
          toast.error(error);
          setOpen(true);
          setIsError(true);
          setLoading(false);
          setEdit(false);
          resetState();
        });
    }
  };
  return (
    <>
      {/* <Row>
        <Col xs={12} md={6} xl={6}>
          {open ? (
            <Alert variant={isError ? "danger" : "success"} msg={msg} />
          ) : (
            ""
          )}
        </Col>
      </Row> */}
      <Row>
        <Col xs={12} md={6} xl={6}>
          <NewUserForm
            handleSubmit={handleSubmit}
            onChangeInput={onChangeInput}
            dataError={dataErrorRef.current}
            loading={loadingRef.current}
          />
        </Col>
      </Row>
      {edit && (
        <Modal
          setShowSignin={setShowSignin}
          showSignin={showSignin}
          formName={"serviceCode"}
          data={dataRef.current}
          setData={setData}
          handleSubmit={handleSubmit}
          onChangeInput={onChangeInput}
          dataError={dataError}
          resetState={resetState}
          loading={loadingRef.current}
        />
      )}
    </>
  );
};
