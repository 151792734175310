import React from "react";

export default () => (
    <tr>
        <td></td>
        <td></td>
        <td></td>
        <td>
            <div className="d-flex justify-content-center">
                <p>No records</p>
            </div>
        </td>
    </tr>
)