import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Card,
	Col,
	Form,
	InputGroup,
	Row,
	Spinner,
} from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import Datetime from 'react-datetime';

export const GeneralInfoForm = () => {
	const [birthday, setBirthday] = useState('');

	return (
		<Card border="light" className="bg-white shadow-sm mb-4">
			<Card.Body>
				<h5 className="mb-4">Create new account</h5>
				<Form>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="firstName">
								<Form.Label>First Name</Form.Label>
								<Form.Control
									required
									type="text"
									placeholder="Enter your first name"
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="lastName">
								<Form.Label>Last Name</Form.Label>
								<Form.Control
									required
									type="text"
									placeholder="Also your last name"
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col md={6} className="mb-3">
							<Form.Group id="birthday">
								<Form.Label>Birthday</Form.Label>
								<Datetime
									timeFormat={false}
									onChange={setBirthday}
									renderInput={(props, openCalendar) => (
										<InputGroup>
											<InputGroup.Text>
												<FontAwesomeIcon icon={faCalendarAlt} />
											</InputGroup.Text>
											<Form.Control
												required
												type="text"
												value={
													birthday ? moment(birthday).format('MM/DD/YYYY') : ''
												}
												placeholder="mm/dd/yyyy"
												onFocus={openCalendar}
												onChange={() => {}}
											/>
										</InputGroup>
									)}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="gender">
								<Form.Label>Gender</Form.Label>
								<Form.Select defaultValue="0">
									<option value="0">Gender</option>
									<option value="1">Female</option>
									<option value="2">Male</option>
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="emal">
								<Form.Label>Email</Form.Label>
								<Form.Control
									required
									type="email"
									placeholder="name@company.com"
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="phone">
								<Form.Label>Phone</Form.Label>
								<Form.Control
									required
									type="number"
									placeholder="+12-345 678 910"
								/>
							</Form.Group>
						</Col>
					</Row>

					<h5 className="my-4">Address</h5>
					<Row>
						<Col sm={9} className="mb-3">
							<Form.Group id="address">
								<Form.Label>Address</Form.Label>
								<Form.Control
									required
									type="text"
									placeholder="Enter your home address"
								/>
							</Form.Group>
						</Col>
						<Col sm={3} className="mb-3">
							<Form.Group id="addressNumber">
								<Form.Label>Number</Form.Label>
								<Form.Control required type="number" placeholder="No." />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={4} className="mb-3">
							<Form.Group id="city">
								<Form.Label>City</Form.Label>
								<Form.Control required type="text" placeholder="City" />
							</Form.Group>
						</Col>
						<Col sm={4} className="mb-3">
							<Form.Group className="mb-2">
								<Form.Label>Select state</Form.Label>
								<Form.Select id="state" defaultValue="0">
									<option value="0">State</option>
									<option value="AL">Alabama</option>
									<option value="AK">Alaska</option>
									<option value="AZ">Arizona</option>
									<option value="AR">Arkansas</option>
									<option value="CA">California</option>
									<option value="CO">Colorado</option>
									<option value="CT">Connecticut</option>
									<option value="DE">Delaware</option>
									<option value="DC">District Of Columbia</option>
									<option value="FL">Florida</option>
									<option value="GA">Georgia</option>
									<option value="HI">Hawaii</option>
									<option value="ID">Idaho</option>
									<option value="IL">Illinois</option>
									<option value="IN">Indiana</option>
									<option value="IA">Iowa</option>
									<option value="KS">Kansas</option>
									<option value="KY">Kentucky</option>
									<option value="LA">Louisiana</option>
									<option value="ME">Maine</option>
									<option value="MD">Maryland</option>
									<option value="MA">Massachusetts</option>
									<option value="MI">Michigan</option>
									<option value="MN">Minnesota</option>
									<option value="MS">Mississippi</option>
									<option value="MO">Missouri</option>
									<option value="MT">Montana</option>
									<option value="NE">Nebraska</option>
									<option value="NV">Nevada</option>
									<option value="NH">New Hampshire</option>
									<option value="NJ">New Jersey</option>
									<option value="NM">New Mexico</option>
									<option value="NY">New York</option>
									<option value="NC">North Carolina</option>
									<option value="ND">North Dakota</option>
									<option value="OH">Ohio</option>
									<option value="OK">Oklahoma</option>
									<option value="OR">Oregon</option>
									<option value="PA">Pennsylvania</option>
									<option value="RI">Rhode Island</option>
									<option value="SC">South Carolina</option>
									<option value="SD">South Dakota</option>
									<option value="TN">Tennessee</option>
									<option value="TX">Texas</option>
									<option value="UT">Utah</option>
									<option value="VT">Vermont</option>
									<option value="VA">Virginia</option>
									<option value="WA">Washington</option>
									<option value="WV">West Virginia</option>
									<option value="WI">Wisconsin</option>
									<option value="WY">Wyoming</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col sm={4}>
							<Form.Group id="zip">
								<Form.Label>ZIP</Form.Label>
								<Form.Control required type="tel" placeholder="ZIP" />
							</Form.Group>
						</Col>
					</Row>
					<div className="mt-3">
						<Button variant="primary" type="submit">
							Save All
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

export const NewUserForm = ({
	loading,
	dataError,
	handleSubmit,
	onChangeInput,
}) => {
	return (
		<Card border="light" className="bg-white shadow-sm mb-4">
			<Card.Body>
				<h5 className="mb-4">Create new account</h5>
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col md={12} className="mb-3">
							<Form.Group>
								<Form.Label>Username *</Form.Label>
								<Form.Control
									name="userName"
									type="text"
									onChange={onChangeInput}
									placeholder="Username.."
									isInvalid={dataError.userName}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.userName}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={12} className="mb-3">
							<Form.Group>
								<Form.Group id="password" className="mb-4">
									<Form.Label>Password *</Form.Label>
									<InputGroup>
										<Form.Control
											type="password"
											name="password"
											placeholder="Password.."
											onChange={onChangeInput}
											isInvalid={dataError.password}
										/>
										<Form.Control.Feedback type="invalid">
											{dataError.password}
										</Form.Control.Feedback>
									</InputGroup>
								</Form.Group>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={12} className="mb-3">
							<Form.Group id="role">
								<Form.Label>Select user role</Form.Label>
								<fieldset>
									<Form.Check
										defaultChecked
										type="radio"
										defaultValue="agent"
										label="Agent"
										name="role"
									/>

									<Form.Check
										disabled
										type="radio"
										defaultValue="option2"
										label="Customer care"
										name="exampleRadios"
										id="radio2"
										htmlFor="radio2"
									/>
								</fieldset>
								<Form.Control.Feedback type="invalid">
									{dataError.role}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<div className="mt-3">
						<Button
							variant="primary"
							disabled={loading}
							type="submit"
							className="shadow-none"
						>
							Save All
							{loading ? <Spinner animation="border" size="sm" /> : ''}
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

export const EditProfileForm = ({
	loading,
	dataError,
	handleSubmit,
	onChangeInput,
	data,
}) => {
	return (
		<Card border="light" className="bg-white shadow-sm mb-4">
			<Card.Body>
				<h5 className="mb-4">Update profile info</h5>
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col sm={12} className="mb-3">
							<Form.Group>
								<Form.Label>Username *</Form.Label>
								<Form.Control
									name="userName"
									type="text"
									onChange={onChangeInput}
									placeholder="Username.."
									isInvalid={dataError.userName}
									value={data.userName}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.userName}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col sm={12} className="mb-3">
							<Form.Group id="role">
								<Form.Label htmlFor="email">Your Email *</Form.Label>
								<InputGroup id="email">
									<Form.Control
										autoFocus
										isInvalid={dataError.emailError}
										onChange={onChangeInput}
										placeholder="Your email"
										value={data.email}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.emailError}
									</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={12} className="mb-3">
							<Form.Group>
								<Form.Group id="password" className="mb-4">
									<Form.Label>New password (optional)</Form.Label>
									<InputGroup>
										<Form.Control
											type="password"
											name="password"
											placeholder="Only fill if you need to create a new password"
											onChange={onChangeInput}
											isInvalid={dataError.password}
										/>
										<Form.Control.Feedback type="invalid">
											{dataError.password}
										</Form.Control.Feedback>
									</InputGroup>
								</Form.Group>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={12} className="mb-3">
							<Form.Group>
								<Form.Group id="password" className="mb-4">
									<Form.Label>Your password *</Form.Label>
									<InputGroup>
										<Form.Control
											type="password"
											name="myPassword"
											placeholder="Your password.."
											onChange={onChangeInput}
											isInvalid={dataError.myPassword}
										/>
										<Form.Control.Feedback type="invalid">
											{dataError.myPassword}
										</Form.Control.Feedback>
									</InputGroup>
								</Form.Group>
							</Form.Group>
						</Col>
					</Row>
					<div className="mt-3">
						<Button
							variant="primary"
							disabled={loading}
							type="submit"
							className="shadow-none"
						>
							Save All
							{loading ? <Spinner animation="border" size="sm" /> : ''}
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

export const NewCategoryForm = ({
	onChangeInput,
	agents,
	data,
	handleSubmit,
	dataError,
	setData,
	dataRef,
	loading,
}) => {
	return (
		<Card border="light" className="bg-white shadow-sm mb-4">
			<Card.Body>
				<h5 className="mb-4">Create new category</h5>
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="firstName">
								<Form.Label>Name *</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="name"
									type="text"
									placeholder="Enter category name"
									isInvalid={dataError.name}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.name}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Ordinary *</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="ordinary"
									type="number"
									isInvalid={dataError.ordinary}
									placeholder="Enter price for this category"
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.ordinary}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Kibimba </Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="kibimba"
									type="number"
									isInvalid={dataError.kibimba}
									placeholder="Enter price for this category"
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.kibimba}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>VIP *</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="vip"
									type="number"
									isInvalid={dataError.vip}
									placeholder="Enter price for this category"
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.vip}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>VVIP *</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="vvip"
									type="number"
									isInvalid={dataError.vvip}
									placeholder="Enter price for this category"
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.vvip}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Olukalala</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="olukalala"
									type="number"
									isInvalid={dataError.olukalala}
									placeholder="Enter price for this category"
									value={data.olukalala}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.olukalala}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Single and Corporate</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="singleandcorporate"
									type="number"
									isInvalid={dataError.singleandcorporate}
									placeholder="Enter price for this category"
									value={data.singleandcorporate}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.singleandcorporate}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Overs</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="overs"
									type="number"
									isInvalid={dataError.overs}
									placeholder="Enter price for this category"
									value={data.overs}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.overs}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Akatambula</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="akatambula"
									type="number"
									isInvalid={dataError.akatambula}
									placeholder="Enter price for this category"
									value={data.akatambula}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.akatambula}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Family</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="family"
									type="number"
									isInvalid={dataError.family}
									placeholder="Enter price for this category"
									value={data.family}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.family}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Early kickoff</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="earlykickoff"
									type="number"
									isInvalid={dataError.earlykickoff}
									placeholder="Enter price for this category"
									value={data.earlykickoff}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.earlykickoff}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Doubles</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="doubles"
									type="number"
									isInvalid={dataError.doubles}
									placeholder="Enter price for this category"
									value={data.doubles}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.doubles}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Late kickoff</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="latekickoff"
									type="number"
									isInvalid={dataError.latekickoff}
									placeholder="Enter price for this category"
									value={data.latekickoff}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.latekickoff}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Normal</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="normal"
									type="number"
									isInvalid={dataError.normal}
									placeholder="Enter price for this category"
									value={data.normal}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.normal}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Single</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="single"
									type="number"
									isInvalid={dataError.single}
									placeholder="Enter price for this category"
									value={data.single}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.single}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Odd 2</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="odd2"
									type="number"
									isInvalid={dataError.odd2}
									placeholder="Enter price for this category"
									value={data.odd2}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.odd2}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Odd 3</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="odd3"
									type="number"
									isInvalid={dataError.odd3}
									placeholder="Enter price for this category"
									value={data.odd3}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.odd3}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Super</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="supper"
									type="number"
									isInvalid={dataError.supper}
									placeholder="Enter price for this category"
									value={data.supper}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.supper}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Corporate</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="corporate"
									type="number"
									isInvalid={dataError.corporate}
									placeholder="Enter price for this category"
									value={data.corporate}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.corporate}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="agent">
								<Form.Label>Agent *</Form.Label>
								<Form.Select
									isInvalid={dataError.agent}
									name="agent"
									onChange={onChangeInput}
								>
									<option value="0">--Select--</option>
									{agents.map((item) => (
										<option key={item._id} value={item._id}>
											{item.userName}
										</option>
									))}
								</Form.Select>
								<Form.Control.Feedback type="invalid">
									{dataError.agent}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Description</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="description"
									as="textarea"
									rows="3"
									placeholder="Description....."
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.description}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<div className="mt-3">
						<Button
							variant="primary"
							type="submit"
							disabled={loading}
							className="shadow-none"
						>
							Save All
							{loading ? <Spinner animation="border" size="sm" /> : ''}
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

export const NewServiceCodeForm = ({
	onChangeInput,
	data,
	handleSubmit,
	dataError,
	setData,
	dataRef,
	loading,
}) => {
	return (
		<Card border="light" className="bg-white shadow-sm mb-4">
			<Card.Body>
				<h5 className="mb-4">Register service codes</h5>
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Name *</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="name"
									type="text"
									placeholder="Enter code"
									isInvalid={dataError.name}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.name}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Category *</Form.Label>
								<Form.Select
									isInvalid={dataError.category}
									name="category"
									onChange={onChangeInput}
								>
									<option value="0">--Select--</option>
									{data.map((item) => (
										<option key={item._id} value={item._id}>
											{item.name}
										</option>
									))}
								</Form.Select>
								<Form.Control.Feedback type="invalid">
									{dataError.category}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Description</Form.Label>
								<Form.Control
									onChange={onChangeInput}
									name="description"
									as="textarea"
									rows="3"
									placeholder="Description....."
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.description}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<div className="mt-3">
						<Button
							variant="primary"
							type="submit"
							disabled={loading}
							className="shadow-none"
						>
							Save All
							{loading ? <Spinner animation="border" size="sm" /> : ''}
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

export const NewContentForm = ({
	onChangeInput,
	categories,
	handleSubmit,
	dataError,
	loading,
	data,
	setData,
	charsLength,
}) => {
	return (
		<Card border="light" className="bg-white shadow-sm mb-4">
			<Card.Body>
				<h5 className="mb-4">Create new content</h5>
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Text Content *</Form.Label>
								<Form.Control
									as="textarea"
									rows="3"
									name="message"
									placeholder="Text message....."
									maxLength="160"
									onChange={onChangeInput}
									isInvalid={dataError.message}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.message}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<span style={{ fontSize: '12px' }}>{charsLength}/160</span>
							<span style={{ marginLeft: '2px' }}>Characters</span>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Category *</Form.Label>
								<Form.Select
									isInvalid={dataError.category}
									name="category"
									onChange={onChangeInput}
								>
									<option value="0">--Select--</option>
									{categories.map((item) => (
										<option key={item._id} value={item._id}>
											{item.name}
										</option>
									))}
								</Form.Select>
								<Form.Control.Feedback type="invalid">
									{dataError.category}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Category level *</Form.Label>
								<Form.Select
									isInvalid={dataError.categoryLevel}
									name="categoryLevel"
									onChange={onChangeInput}
								>
									<option value="0">--Select--</option>
									<option value="ordinary">Ordinary</option>
									<option value="vip">VIP</option>
									<option value="vvip">VVIP</option>
									<option value="olukalala">Olukalala</option>
									<option value="singleandcorporate">
										Single and Corporate
									</option>
									<option value="overs">Overs</option>
									<option value="akatambula">Akatambula</option>
									<option value="family">Family</option>
									<option value="earlykickoff">Early kickoff</option>
									<option value="doubles">Doubles</option>
									<option value="latekickoff">Late kickoff</option>
									<option value="normal">Normal</option>
									<option value="single">Single</option>
									<option value="odd2">Odd 2</option>
									<option value="odd3">Odd 3</option>
									<option value="supper">Super</option>
									<option value="corporate">Corporate</option>
									<option value="kibimba">Kibimba</option>
								</Form.Select>
								<Form.Control.Feedback type="invalid">
									{dataError.categoryLevel}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Description</Form.Label>
								<Form.Control onChange={onChangeInput} name="description" />
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="activate">
								<Form.Label>Activate content</Form.Label>
								<Form.Check
									name="active"
									onChange={(e) =>
										setData({ ...data, active: e.currentTarget.checked })
									}
									label="Activate"
									id="activate"
									htmlFor="activate"
								/>
							</Form.Group>
						</Col>
					</Row>
					<div className="d-grid gap-2 d-md-block mt-3">
						<Button variant="primary" type="submit" disabled={loading}>
							Add new
							{loading ? <Spinner animation="border" size="sm" /> : ''}
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

export const NewMessage = ({
	onChangeInput,
	handleSubmit,
	dataError,
	loading,
	charsLength,
}) => {
	return (
		<Card border="light" className="bg-white shadow-sm mb-4">
			<Card.Body>
				<h5 className="mb-4">New message</h5>
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Text Content *</Form.Label>
								<Form.Control
									as="textarea"
									rows="3"
									name="message"
									placeholder="Text message....."
									maxLength="160"
									onChange={onChangeInput}
									isInvalid={dataError.message}
								/>
								<Form.Control.Feedback type="invalid">
									{dataError.message}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label> Phone Number *</Form.Label>

								<Form.Control
									onChange={onChangeInput}
									name="phoneNumber"
									type="text"
									placeholder="Enter phone number"
									isInvalid={dataError.phoneNumber}
								/>
								<Form.Label> Format (2567xxxxxxxx)</Form.Label>
								<Form.Control.Feedback type="invalid">
									{dataError.phoneNumber}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<span style={{ fontSize: '12px' }}>{charsLength}/160</span>
							<span style={{ marginLeft: '2px' }}>Characters</span>
						</Col>
					</Row>

					<Row>
						<Col md={6} className="mb-3">
							<Form.Group>
								<Form.Label>Category level *</Form.Label>
								<Form.Select
									isInvalid={dataError.categoryLevel}
									name="categoryLevel"
									onChange={onChangeInput}
								>
									<option value="0">--Select--</option>
									<option value="ordinary">Ordinary</option>
									<option value="vip">VIP</option>
									<option value="vvip">VVIP</option>
									<option value="olukalala">Olukalala</option>
									<option value="singleandcorporate">
										Single and Corporate
									</option>
									<option value="overs">Overs</option>
									<option value="akatambula">Akatambula</option>
									<option value="family">Family</option>
									<option value="earlykickoff">Early kickoff</option>
									<option value="doubles">Doubles</option>
									<option value="latekickoff">Late kickoff</option>
									<option value="normal">Normal</option>
									<option value="single">Single</option>
									<option value="odd2">Odd 2</option>
									<option value="odd3">Odd 3</option>
									<option value="kibimba">Kibimba</option>
								</Form.Select>
								<Form.Control.Feedback type="invalid">
									{dataError.categoryLevel}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<div className="d-grid gap-2 d-md-block mt-3">
						<Button variant="primary" type="submit" disabled={loading}>
							Send
							{loading ? <Spinner animation="border" size="sm" /> : ''}
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};
