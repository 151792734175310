/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import axios from "axios";
import { Col, Row } from "@themesberg/react-bootstrap";
import { NewServiceCodeForm } from "../../components/Forms";
import useState from "react-usestateref";
import Alert from "../examples/alert";
import Modal from "./modal";
import { toast } from "react-toastify";

export default ({
  showSignin,
  setShowSignin,
  edit,
  setEdit,
  editData,
  id,
  resetState,
}) => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading, loadingRef] = useState(false);
  const [msg, setMsg] = useState("");
  const [variant, setVariant] = useState("danger");

  const [data, setData, dataRef] = useState({
    name: "",
    description: "",
    category: "",
  });

  const [dataError, setDataError, dataErrorRef] = useState({
    name: "",
    description: "",
    category: "",
  });

  const onChangeInput = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  React.useEffect(() => {
    setData({ ...editData });
  }, [edit]);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  };

  const checkForm = async () => {
    if (!dataRef.current.category || dataRef.current.category === "0") {
      setDataError({
        ...dataErrorRef.current,
        category: "Please select the category",
      });
    } else {
      setDataError({ ...dataErrorRef.current, category: "" });
    }

    if (!dataRef.current.name) {
      setDataError({
        ...dataErrorRef.current,
        name: "Please provide the code",
      });
    } else {
      setDataError({ ...dataErrorRef.current, name: "" });
    }
  };

  React.useEffect(() => {
    getcategories();
  }, []);

  const getcategories = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_BACKEND_API
        }/v1/category?page=${1}&limit=${100}`,
        config
      )
      .then((res) => {
        setCategories(res.data.results);
      })
      .catch((err) => {
        setCategories([]);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await checkForm();

    if (dataErrorRef.current.category || dataErrorRef.current.name) {
      return;
    }
    setLoading(true);

    if (!edit) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/v1/short-code`,
          data,
          config
        )
        .then((res) => {
          setOpen(true);
          // setMsg("Successfully registered a service code");
          toast.success("Successfully registered a service code");
          setVariant("success");
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 500) {
            // setMsg(
            //   `Service code ${data.name} is already taken, please try another`
            // );
            toast.error(
              `Service code ${data.name} is already taken, please try another`
            );
            setLoading(false);
          } else {
            // setMsg("Unable to register service code");
            toast.error("Unable to register service code");
            setLoading(false);
          }
          setVariant("danger");
          setOpen(true);
          setLoading(false);
        });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_API}/v1/short-code/${id}`,
          data,
          config
        )
        .then((res) => {
          setOpen(true);
          // setMsg("Successfully registered a service code");
          toast.success("Successfully registered a service code");
          setVariant("success");
          setLoading(false);
          setShowSignin(false);
          resetState();
        })
        .catch((err) => {
          if (err.response.status === 500) {
            // setMsg(
            //   `Service code ${data.name} is already taken, please try another`
            // );
            toast.error(
              `Service code ${data.name} is already taken, please try another`
            );
            setLoading(false);
            setShowSignin(false);
          } else {
            // setMsg("Unable to register service code");
            toast.error("Unable to register service code");
            setLoading(false);
            setShowSignin(false);
          }
          setVariant("danger");
          setOpen(true);
          setLoading(false);
          setShowSignin(false);
          resetState();
        });
    }
  };

  return (
    <>
      <Row>
        {/* <Col md={6}>
          {open ? <Alert variant={variant} msg={msg} setOpen={setOpen} /> : ""}
        </Col> */}
        <Col xs={12} xl={12}>
          <NewServiceCodeForm
            dataRef={dataRef}
            setData={setData}
            data={categories.docs ? categories.docs : []}
            handleSubmit={handleSubmit}
            onChangeInput={onChangeInput}
            dataError={dataError}
            loading={loadingRef.current}
          />
        </Col>
      </Row>
      {edit && (
        <Modal
          setShowSignin={setShowSignin}
          showSignin={showSignin}
          formName={"serviceCode"}
          data={dataRef.current}
          setData={setData}
          resetState={resetState}
          handleSubmit={handleSubmit}
          onChangeInput={onChangeInput}
          dataError={dataError}
          loading={loadingRef.current}
          categories={categories.docs ? categories.docs : []}
        />
      )}
    </>
  );
};
