import { Form } from '@themesberg/react-bootstrap';
import React from 'react';

const SelectCode = ({ setCode }) => (
	<Form.Group className="mb-3">
		<Form.Select
			name="code"
			onChange={(event) => {
				setCode(event.target.value);
			}}
			defaultValue={'*210*15*100#'}
			disabled={true}
		>
			<option value={'*210*15*100#'}>*210*15*100#</option>
			{/* <option value={'*210*15*50#'}>*210*15*50#</option> */}
		</Form.Select>
	</Form.Group>
);

export default SelectCode;
