/* eslint-disable no-unused-vars */
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import useState from 'react-usestateref';
import Tabs from '../../helpers/tabs';
import CategoryList from './Categories';
import NewCategory from './NewCategory';

export default () => {
	const [showSignin, setShowSignin] = useState(false);
	const [edit, setEdit] = useState(false);
	const [load, setLoad] = useState(false);

	const [id, setID] = useState('');
	const [editData, setEditData, editDataRef] = useState({
		name: '',
		vip: '',
		vvip: '',
		akatambula: '',
		family: '',
		earlykickoff: '',
		doubles: '',
		latekickoff: '',
		olukalala: '',
		singleandcorporate: '',
		overs: '',
		normal: '',
		single: '',
		ordinary: '',
		kibimba: '',
		supper: '',
		corporate: '',
		agent: '',
		description: '',
	});

	const resetState = () => {
		setEditData({
			name: '',
			vip: '',
			vvip: '',
			akatambula: '',
			family: '',
			earlykickoff: '',
			doubles: '',
			latekickoff: '',
			olukalala: '',
			singleandcorporate: '',
			overs: '',
			normal: '',
			single: '',
			ordinary: '',
			kibimba: '',
			supper: '',
			corporate: '',
			agent: '',
			description: '',
		});
		setEdit(false);
	};
	const handleEditData = (data, id) => {
		setEditData({ ...data });
		setID(id);
		setEdit(true);
		setShowSignin(true);
	};

	const data = [
		{
			route: 'All',
			component: () => (
				<CategoryList load={load} handleEditData={handleEditData} />
			),
		},
		{
			route: <FontAwesomeIcon icon={faPlus} className="me-2" />,
			component: () => (
				<NewCategory
					edit={edit}
					id={id}
					resetState={resetState}
					setEditData={setEditData}
					setEdit={setEdit}
					editData={editDataRef.current}
					showSignin={showSignin}
					setShowSignin={setShowSignin}
				/>
			),
		},
	];
	return <Tabs data={data} load={load} setLoad={setLoad} />;
};
