/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useState from "react-usestateref";
import { Button } from "@themesberg/react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { WorldCupFaliledTransactions } from "../../components/world-cup-2022";
import Datepicker from "../../components/Datepicker";
import SelectCode from "../../components/world-cup-2022/selectCode";

export default ({ load }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [date, setDate] = useState(new Date());
  const [code, setCode] = useState("*210*15*100#");

  const [clickedButton, setClickedButton] = useState([]);

  React.useEffect(() => {
    getData();
  }, [page, load, refresh, date, code]);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  };

  const reloadTransactions = () => {
    setRefresh(!refresh);
  };

  const getData = () => {
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_BACKEND_API
        }/v1/world/cup/analysis/to/give/attention?page=${page}&limit=${50}`,

        {
          name: code,
          date: new Date(date),
        },
        config
      )
      .then((res) => {
        setData(res.data.results);
        setLoading(false);
        toast.success(`Transactions for ${new Date(date).toDateString()}`);
      })
      .catch((err) => {
        setData([]);
        setLoading(false);
        toast.error("Unexpected error");
      });
  };

  const resolveTransaction = (id) => {
    setClickedButton([...clickedButton, id]);
    // setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/v1/world/cup/analysis/to/give/attention/${id}`,
        { isResolved: true },
        config
      )
      .then((res) => {
        // setData(res.data.results);
        // setLoading(false);
        toast.success("Resolved");
      })
      .catch((err) => {
        console.log(err, "---err=----");
        // setData([]);
        // setLoading(false);
        toast.error("Unexpected error");
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Failed transactions</h4>
          <h6>
            Clients with failed transactions are engaged to assist them in
            making successful transactions
          </h6>
          <p>
            <span className="text-success">Yes</span>: The client has been
            engaged and helped to perform a successful transaction
            <br /> <span className="text-warning">No </span>: The client needs
            to be engaged
          </p>
          {/* <p className="mb-0">
            <Button
              onClick={reloadTransactions}
              variant="white"
              className="m-1"
              style={{ fontSize: "14px", color: "#CDCCD0" }}
            >
              <i class="fas fa-sync-alt"></i> <span>Refresh</span>
            </Button>
          </p> */}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <SelectCode setCode={setCode} />
        </div>
        <div className="col-sm-2">
          <Datepicker setDate={setDate} date={date} />
        </div>
        <div className="col-sm-8">
          <Button
            onClick={reloadTransactions}
            variant="white"
            className="m-1"
            style={{ fontSize: "14px", color: "#CDCCD0" }}
          >
            <i class="fas fa-sync-alt"></i> <span>Refresh</span>
          </Button>
        </div>
      </div>

      <WorldCupFaliledTransactions
        data={data}
        loading={loading}
        setPage={setPage}
        resolveTransaction={resolveTransaction}
        clickedButton={clickedButton}
      />
    </>
  );
};
