import React, { useState } from "react";
import { Button, Modal } from "@themesberg/react-bootstrap";

import { WorldCupVoteDetailsTeamList } from "../../../components/world-cup-2022";

const UserVotesDetails = ({ showDefault, handleClose, voteDetails }) => {
  const { count, phoneNumber } = voteDetails;
  return (
    <React.Fragment>
      <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Team selection by user</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <span>{`Phone number: +${phoneNumber}`}</span>
          <br />
          <span>{`Total: ${count}`}</span>
          <br />
          <WorldCupVoteDetailsTeamList data={voteDetails || {}} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default UserVotesDetails;
