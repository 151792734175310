/* eslint-disable no-unused-vars */
import React from "react";
import useState from "react-usestateref";
import UserList from "./UserList";
import NewUsers from "./CreateUser";
import Tabs from "../../helpers/tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default () => {
  const [showSignin, setShowSignin] = useState(false);
  const [edit, setEdit, editRef] = useState(false);
  const [load, setLoad] = useState(false);

  const [id, setID] = useState("");
  const [editData, setEditData, editDataRef] = useState({
    userName: "",
    password: "",
    role: ""
  });

  const handleEditData = (data, id) => {
    setEditData({ ...data });
    setID(id);
    setEdit(true);
    setShowSignin(true);
  };

  const resetState = () => {
    setEditData({
    userName: "",
    password: "",
    role: ""})
    setEdit(false);
  }

  const data = [
    {
      route: "All",
      component: () => <UserList load={load} handleEditData={handleEditData} />,
    },
    {
      route: <FontAwesomeIcon icon={faPlus} className="me-2" />,
      component: () => (
        <NewUsers
          edit={edit}
          resetState={resetState}
          id={id}
          setEdit={setEdit}
          editData={editDataRef.current}
          showSignin={showSignin}
          setShowSignin={setShowSignin}
        />
      ),
    },
  ];
  return <Tabs data={data} load={load} setLoad={setLoad} />;
};
