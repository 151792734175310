/* eslint-disable no-unused-vars */
import {
	faChartPie,
	faCog,
	faHandHoldingUsd,
	faList,
	faPen,
	faPhone,
	faSearch,
	faSignOutAlt,
	faSms,
	faStar,
	faTimes,
	faTrophy,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Accordion,
	Badge,
	Button,
	Image,
	Nav,
	Navbar,
} from '@themesberg/react-bootstrap';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSSTransition } from 'react-transition-group';
import SimpleBar from 'simplebar-react';
import ProfilePicture from '../assets/img/team/profile-picture-3.jpg';
import ReactHero from '../assets/img/technologies/react-hero-logo.svg';
import { Routes } from '../routes';
const jwt = require('jsonwebtoken');

export default () => {
	const location = useLocation();
	const { pathname } = location;
	const [show, setShow] = useState(false);
	const showClass = show ? 'show' : '';

	const onCollapse = () => setShow(!show);
	const CollapsableNavItem = (props) => {
		const { eventKey, title, icon, children = null } = props;
		const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';

		return (
			<Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
				<Accordion.Item eventKey={eventKey}>
					<Accordion.Button
						as={Nav.Link}
						className="d-flex justify-content-between align-items-center"
					>
						<span>
							<span className="sidebar-icon">
								<FontAwesomeIcon icon={icon} />{' '}
							</span>
							<span className="sidebar-text">{title}</span>
						</span>
					</Accordion.Button>
					<Accordion.Body className="multi-level">
						<Nav className="flex-column">{children}</Nav>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		);
	};

	const NavItem = (props) => {
		const {
			title,
			link,
			external,
			target,
			icon,
			image,
			badgeText,
			badgeBg = 'secondary',
			badgeColor = 'primary',
		} = props;
		const classNames = badgeText
			? 'd-flex justify-content-start align-items-center justify-content-between'
			: '';
		const navItemClassName = link === pathname ? 'active' : '';
		const linkProps = external ? { href: link } : { as: Link, to: link };

		return (
			<Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
				<Nav.Link {...linkProps} target={target} className={classNames}>
					<span>
						{icon ? (
							<span className="sidebar-icon">
								<FontAwesomeIcon icon={icon} />{' '}
							</span>
						) : null}
						{image ? (
							<Image
								src={image}
								width={20}
								height={20}
								className="sidebar-icon svg-icon"
							/>
						) : null}

						<span className="sidebar-text">{title}</span>
					</span>
					{badgeText ? (
						<Badge
							pill
							bg={badgeBg}
							text={badgeColor}
							className="badge-md notification-count ms-2"
						>
							{badgeText}
						</Badge>
					) : null}
				</Nav.Link>
			</Nav.Item>
		);
	};

	React.useEffect(() => {
		role();
	}, []);

	const [userRole, setUserRole] = useState('');
	const [userID, setUserID] = useState('');

	const role = () => {
		const token = localStorage.getItem('accessToken');
		if (!token) {
			localStorage.clear();
			return;
		}
		const l = 'unwJqn5ibZOZBsdPNJU9FK8g49EXuxEEnew';
		jwt.verify(token, process.env.JWT_ACCESS_TOKEN_SECRET || l, (err, user) => {
			if (err) {
				localStorage.clear();
				return;
			} else {
				setUserRole(user.role);
				setUserID(user.id);
				return user.role;
			}
		});
	};

	let history = useHistory();
	const handleLogout = () => {
		toast.success('Logged out');
		localStorage.clear();
		history.push('/');
	};

	return (
		<>
			<Navbar
				expand={false}
				collapseOnSelect
				variant="dark"
				className="navbar-theme-primary px-4 d-md-none"
			>
				<Navbar.Brand
					className="me-lg-5"
					as={Link}
					to={Routes.DashboardOverview.path}
				>
					<Image src={ReactHero} className="navbar-brand-light" />
				</Navbar.Brand>
				<Navbar.Toggle
					as={Button}
					aria-controls="main-navbar"
					onClick={onCollapse}
				>
					<span className="navbar-toggler-icon" />
				</Navbar.Toggle>
			</Navbar>
			<CSSTransition timeout={300} in={show} classNames="sidebar-transition">
				<SimpleBar
					className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
				>
					<div className="sidebar-inner px-4 pt-3">
						<div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
							<div className="d-flex align-items-center">
								<div className="user-avatar lg-avatar me-4">
									<Image
										src={ProfilePicture}
										className="card-img-top rounded-circle border-white"
									/>
								</div>
								<div className="d-block">
									<h6>
										{localStorage.getItem('userName')
											? `Hi, ${localStorage.getItem('userName')}`
											: null}
									</h6>
									<Button
										// as={Link}
										onClick={handleLogout}
										variant="secondary"
										size="xs"
										to={Routes.Signin.path}
										className="text-dark"
									>
										<FontAwesomeIcon
											icon={faSignOutAlt}
											className="me-2"
											onClick={handleLogout}
										/>{' '}
										Logout
									</Button>
								</div>
							</div>
							<Nav.Link
								className="collapse-close d-md-none"
								onClick={onCollapse}
							>
								<FontAwesomeIcon icon={faTimes} />
							</Nav.Link>
						</div>
						{userID !== '65a4a99973ad59004c6a9c98' && (
							<Nav className="flex-column pt-3 pt-md-0">
								<NavItem title="Tabz media" image={ReactHero} />

								{/* <NavItem
									title="Overview"
									link={Routes.DashboardOverview.path}
									icon={faChartPie}
								/> */}
								<NavItem
									title="Transactions"
									icon={faHandHoldingUsd}
									link={Routes.Transactions.path}
								/>
								<NavItem
									title="Trans search"
									icon={faSearch}
									link={Routes.FullSearch.path}
								/>
								<NavItem
									title="Failed transactions"
									icon={faSearch}
									link={Routes.FailedTransactions.path}
								/>

								{userRole === 'admin' && (
									<>
										<NavItem
											title="Profile"
											icon={faCog}
											link={Routes.Settings.path}
										/>
										<NavItem
											title="Users"
											icon={faUsers}
											link={Routes.Users.path}
										/>

										<NavItem
											title="Category"
											icon={faList}
											link={Routes.Category.path}
										/>
										<NavItem
											title="Service code"
											icon={faPhone}
											link={Routes.ServiceCode.path}
										/>
									</>
								)}
								<NavItem
									title="Content"
									icon={faPen}
									link={Routes.Content.path}
								/>
								<NavItem title="Sms" icon={faSms} link={Routes.Message.path} />
							</Nav>
						)}

						{userID === '65a4a99973ad59004c6a9c98' && (
							<Nav className="flex-column pt-3 pt-md-0">
								{/* Afcon 2024 */}
								<NavItem title="Tabz media" image={ReactHero} />
								<NavItem
									title="Afcon Transactions"
									icon={faHandHoldingUsd}
									link={Routes.Afcon2024Transactions.path}
								/>
								<NavItem
									title="Failed Transactions"
									icon={faPen}
									link={Routes.Afcon2024Failed.path}
								/>
								<NavItem
									title="Trans Search"
									icon={faSearch}
									link={Routes.Afcon2024Search.path}
								/>
								<NavItem
									title="Ranking"
									icon={faTrophy}
									link={Routes.Afcon2024GeneralRanking.path}
								/>
							</Nav>
						)}
					</div>
				</SimpleBar>
			</CSSTransition>
		</>
	);
};
