/* eslint-disable no-unused-vars */
import { Button } from '@themesberg/react-bootstrap';
import moment from 'moment';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useState from 'react-usestateref';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCopy } from '@fortawesome/free-solid-svg-icons';

import {
	ButtonGroup,
	Card,
	Col,
	Dropdown,
	Nav,
	Pagination,
	ProgressBar,
	Row,
	Table,
} from '@themesberg/react-bootstrap';

import Empty from '../../helpers/empty';
import Loader from '../../helpers/loader';

const formatAMPM = (date) => {
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'pm' : 'am';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;
	return strTime;
};

export const WorldCupTransactions = ({ loading, setPage, data }) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const tableStyle = {
		// height: "10px",
		// padding: "3px"
	};

	const TableRow = (data) => {
		const {
			isComplete,
			category,
			shortCode,
			phoneNumber,
			network,
			createdAt,
			callbackTime,
			no,
			isDeleted,
			categoryLevel,
			name,
			teamName,
		} = data;

		let statusVariant;
		let statusText;
		if (isComplete) {
			statusVariant = 'success';
			statusText = 'processed';
		} else {
			if (isDeleted) {
				statusVariant = 'danger';
				statusText = 'failed';
			} else {
				statusVariant = 'warning';
				statusText = 'pending';
			}
		}

		return (
			<tr style={tableStyle}>
				<td>
					<Card.Link className="fw-normal">{no}</Card.Link>
				</td>
				<td>
					<span className="fw-normal">
						{`${moment(callbackTime).format('DD MMMM YYYY')} ${formatAMPM(
							new Date(callbackTime)
						)}`}
					</span>
				</td>
				<td>
					<span className="fw-normal">{name}</span>
				</td>

				{/* <td>
          <span className="fw-normal">
            {phoneNumber.slice(0, phoneNumber.length - 3) + "***"}
          </span>
        </td> */}
				<td>
					<span className="fw-normal">{phoneNumber}</span>
				</td>
				<td>
					<span className="fw-normal">{teamName}</span>
				</td>

				<td>
					<span className={`fw-normal text-${statusVariant}`}>
						{statusText}
					</span>
				</td>
				<td>
					<span className="fw-normal">{network}</span>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr style={tableStyle}>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Date/Time</th>
							<th className="border-bottom">Service code</th>
							<th className="border-bottom">From</th>
							<th className="border-bottom">Team</th>
							<th className="border-bottom">Status</th>
							<th className="border-bottom">Network</th>
						</tr>
					</thead>
					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`trans-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const WorldCupFaliledTransactions = ({
	loading,
	setPage,
	data,
	resolveTransaction,
	clickedButton,
}) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const tableStyle = {
		// height: "10px",
		// padding: "3px"
	};

	const TableRow = (data) => {
		const {
			isComplete,
			category,
			shortCode,
			phoneNumber,
			network,
			createdAt,
			callbackTime,
			no,
			isDeleted,
			categoryLevel,
			isResolved,
			_id,
			name,
			teamName,
		} = data;

		let statusVariant;
		let statusText;

		if (isComplete) {
			statusVariant = 'success';
			statusText = 'processed';
		} else {
			if (isDeleted) {
				statusVariant = 'danger';
				statusText = 'failed';
			} else {
				statusVariant = 'warning';
				statusText = 'pending';
			}
		}

		return (
			<tr style={tableStyle}>
				<td>
					<Card.Link className="fw-normal">{no}</Card.Link>
				</td>
				<td>
					<span className="fw-normal">
						{`${moment(createdAt).format('DD MMMM YYYY')} ${formatAMPM(
							new Date(createdAt)
						)}`}
					</span>
				</td>
				<td>
					{!callbackTime ? (
						<span>N/A</span>
					) : (
						<span className="fw-normal">
							{`${moment(callbackTime).format('DD MMMM YYYY')} ${formatAMPM(
								new Date(callbackTime)
							)}`}
						</span>
					)}
				</td>
				<td>
					<span className="fw-normal">{name}</span>
				</td>

				<td>
					<span className="fw-normal">{phoneNumber}</span>
				</td>
				<td>
					<span className={`fw-normal`}>{teamName}</span>
				</td>

				<td>
					<span className={`fw-normal text-${statusVariant}`}>
						{statusText}
					</span>
				</td>
				<td>
					<span className="fw-normal">{network}</span>
				</td>
				<td>
					<span
						className={`fw-normal text-${
							isResolved || clickedButton.includes(_id) ? 'success' : 'warning'
						}`}
					>
						{isResolved || clickedButton.includes(_id) ? 'Yes' : 'No'}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						<button
							onClick={() => resolveTransaction(_id)}
							className={`btn ${
								isResolved || clickedButton.includes(_id)
									? 'btn-outline-success disabled'
									: 'btn-outline-warning'
							} `}
						>
							{isResolved || clickedButton.includes(_id)
								? 'Resolved'
								: 'Resolve'}
						</button>
					</span>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr style={tableStyle}>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Call Time</th>
							<th className="border-bottom">Response Time</th>
							<th className="border-bottom">Service code</th>
							<th className="border-bottom">From</th>
							<th className="border-bottom">Team</th>
							<th className="border-bottom">Status</th>
							<th className="border-bottom">Network</th>
							<th className="border-bottom">Resolved</th>
							<th className="border-bottom">Action</th>
						</tr>
					</thead>
					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`trans-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const WorldCupSearchByPhoneTable = ({ loading, setPage, data }) => {
	const [copy, setCopy] = useState({ copied: false, id: '' });

	const handleCopy = (id) => {
		setCopy({ ...copy, copied: true, id });
	};
	const dataList = data ? data : [];
	const totalRecords = dataList.length;

	const tableStyle = {
		// height: "10px",
		// padding: "3px"
	};

	const TableRow = (data) => {
		const {
			isComplete,
			category,
			shortCode,
			_id,
			name,
			phoneNumber,
			messageStatuses,
			network,
			createdAt,
			callbackTime,
			no,
			isDeleted,
			teamName,
		} = data;

		let statusVariant;
		let statusText;
		if (isComplete) {
			statusVariant = 'success';
			statusText = 'processed';
		} else {
			if (isDeleted) {
				statusVariant = 'danger';
				statusText = 'failed';
			} else {
				statusVariant = 'warning';
				statusText = 'pending';
			}
		}

		return (
			<tr style={tableStyle}>
				<td>
					<Card.Link className="fw-normal">{no}</Card.Link>
				</td>
				<td>
					<span className="fw-normal">
						{`${moment(createdAt).format('DD MMMM YYYY')} ${formatAMPM(
							new Date(createdAt)
						)}`}
					</span>
				</td>
				<td>
					{!callbackTime ? (
						<span>N/A</span>
					) : (
						<span className="fw-normal">
							{`${moment(callbackTime).format('DD MMMM YYYY')} ${formatAMPM(
								new Date(callbackTime)
							)}`}
						</span>
					)}
				</td>
				<td>
					<span className="fw-normal">{name}</span>
				</td>

				{/* <td>
          <span className="fw-normal">
            {phoneNumber.slice(0, phoneNumber.length - 3) + "***"}
          </span>
        </td> */}
				<td>
					<span className="fw-normal">{phoneNumber}</span>
				</td>
				<td>
					<span className="fw-normal">{teamName}</span>
				</td>

				<td>
					<span className={`fw-normal text-${statusVariant}`}>
						{statusText}
					</span>
				</td>
				<td>
					<span className={`fw-normal text-${statusVariant}`}>
						{messageStatuses}
					</span>
				</td>
				<td>
					<span className="fw-normal">{network}</span>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr style={tableStyle}>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Call time</th>
							<th className="border-bottom">Response time</th>
							<th className="border-bottom">Service code</th>
							<th className="border-bottom">From</th>
							<th className="border-bottom">Team</th>
							<th className="border-bottom">Status</th>
							<th className="border-bottom">Message</th>
							<th className="border-bottom">Network</th>
						</tr>
					</thead>
					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`trans-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const WorldCupGeneralRanking = ({
	loading,
	setPage,
	data,
	limit,
	page,
	setShowDefault,
	setVoteDetails,
}) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const TableRow = (data) => {
		const { serviceCode, phoneNumber, _id, no, count } = data;

		return (
			<tr>
				<td>
					<Card.Link className="fw-normal">
						{no + (Number(page) * Number(limit) - limit)}
					</Card.Link>
				</td>
				<td>
					<span className="fw-normal">{_id}</span>
				</td>
				<td>
					<span className="fw-normal">{`*210*15*100#`}</span>
				</td>
				<td>
					<span className="fw-normal">{count}</span>
				</td>
				<td>
					<Button
						variant="outline-primary"
						className="m-1"
						onClick={() => {
							setShowDefault(true);
							setVoteDetails(data);
						}}
					>
						View more
					</Button>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">#</th>
							<th className="border-bottom">phoneNumber</th>
							<th className="border-bottom">Service code</th>
							<th className="border-bottom">Votes</th>
							<th className="border-bottom">Action</th>
						</tr>
					</thead>
					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
								<td></td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`trans-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.prevPage ? false : true}
									onClick={() => setPage(data.prevPage)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.nextPage ? false : true}
									onClick={() => setPage(data.nextPage)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.totalDocs}</b>{' '}
							entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const WorldCupWeek2Ranking = ({
	loading,
	setPage,
	data,
	limit,
	page,
	setShowDefault,
	setVoteDetails,
}) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const TableRow = (data) => {
		const { serviceCode, phoneNumber, no, count } = data;

		return (
			<tr>
				<td>
					<Card.Link className="fw-normal">
						{no + (Number(page) * Number(limit) - limit)}
					</Card.Link>
				</td>
				<td>
					<span className="fw-normal">{phoneNumber}</span>
				</td>
				<td>
					<span className="fw-normal">{serviceCode}</span>
				</td>
				<td>
					<span className="fw-normal">{count}</span>
				</td>
				<td>
					<Button
						variant="outline-primary"
						className="m-1"
						onClick={() => {
							setShowDefault(true);
							setVoteDetails(data);
						}}
					>
						View more
					</Button>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">#</th>
							<th className="border-bottom">phoneNumber</th>
							<th className="border-bottom">Service code</th>
							<th className="border-bottom">Votes</th>
							<th className="border-bottom">Action</th>
						</tr>
					</thead>
					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
								<td></td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`trans-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const WorldCupWeek3Ranking = ({
	loading,
	setPage,
	data,
	limit,
	page,
	setShowDefault,
	setVoteDetails,
}) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const TableRow = (data) => {
		const { serviceCode, phoneNumber, no, count } = data;

		return (
			<tr>
				<td>
					<Card.Link className="fw-normal">
						{no + (Number(page) * Number(limit) - limit)}
					</Card.Link>
				</td>
				<td>
					<span className="fw-normal">{phoneNumber}</span>
				</td>
				<td>
					<span className="fw-normal">{serviceCode}</span>
				</td>
				<td>
					<span className="fw-normal">{count}</span>
				</td>
				<td>
					<Button
						variant="outline-primary"
						className="m-1"
						onClick={() => {
							setShowDefault(true);
							setVoteDetails(data);
						}}
					>
						View more
					</Button>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">#</th>
							<th className="border-bottom">phoneNumber</th>
							<th className="border-bottom">Service code</th>
							<th className="border-bottom">Votes</th>
							<th className="border-bottom">Action</th>
						</tr>
					</thead>
					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
								<td></td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`trans-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const WorldCupWeek4Ranking = ({
	loading,
	setPage,
	data,
	limit,
	page,
	setShowDefault,
	setVoteDetails,
}) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const TableRow = (data) => {
		const { serviceCode, phoneNumber, no, count } = data;

		return (
			<tr>
				<td>
					<Card.Link className="fw-normal">
						{no + (Number(page) * Number(limit) - limit)}
					</Card.Link>
				</td>
				<td>
					<span className="fw-normal">{phoneNumber}</span>
				</td>
				<td>
					<span className="fw-normal">{serviceCode}</span>
				</td>
				<td>
					<span className="fw-normal">{count}</span>
				</td>
				<td>
					<Button
						variant="outline-primary"
						className="m-1"
						onClick={() => {
							setShowDefault(true);
							setVoteDetails(data);
						}}
					>
						View more
					</Button>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">#</th>
							<th className="border-bottom">phoneNumber</th>
							<th className="border-bottom">Service code</th>
							<th className="border-bottom">Votes</th>
							<th className="border-bottom">Action</th>
						</tr>
					</thead>
					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
								<td></td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`trans-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const WorldCupVoteDetailsTeamList = ({ data }) => {
	const TableRow = (record) => {
		const { no, teamName, teamName2, createdAt } = record;

		return (
			<tr>
				<td>
					<Card.Link className="fw-normal">{no}</Card.Link>
				</td>
				<td className="fw-normal">
					{`${moment(createdAt).format('DD MMMM YYYY')} ${formatAMPM(
						new Date(createdAt)
					)}`}
				</td>
				<td>
					<span className="fw-normal">
						{teamName2 ? `${teamName2} vs ${teamName}` : 'General prediction'}
						{/* {teamName2}vs{teamName} */}
					</span>
				</td>
				<td>
					<span className="fw-normal">{teamName}</span>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Date</th>
							<th className="border-bottom">Game</th>
							<th className="border-bottom">Team</th>
						</tr>
					</thead>
					<tbody>
						{data?.transactions?.reverse().map((t, i) => (
							<TableRow no={i + 1} key={`trans-${t._id}`} {...t} />
						))}
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	);
};
