/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import useState from "react-usestateref";
import axios from "axios";
import Alert from "../../helpers/alert";
import { Col, Row } from "@themesberg/react-bootstrap";
import { EditProfileForm } from "../../components/Forms";
import Error500 from "../../helpers/500";
import { toast } from "react-toastify";

export default () => {
  const [data, setData, dataRef] = useState({
    userName: "",
    password: "",
    myPassword: "",
  });

  const [dataError, setDataError, dataErrorRef] = useState({
    userName: "",
    password: "",
    myPassword: "",
  });

  const [loading, setLoading, loadingRef] = useState(false);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const onChangeInput = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  React.useEffect(() => {
    getProfile();
  }, []);

  const checkForm = async () => {
    if (dataRef.current.password) {
      if (dataRef.current.password.length < 8) {
        setDataError({
          ...dataErrorRef.current,
          password: "Password must be atleast 8 characters long",
        });
      } else {
        setDataError({ ...dataErrorRef.current, password: "" });
      }
    } else {
      setDataError({ ...dataErrorRef.current, password: "" });
    }
    if (!dataRef.current.myPassword) {
      setDataError({
        ...dataErrorRef.current,
        myPassword: "Please provide the your password",
      });
    } else {
      if (dataRef.current.myPassword.length < 8) {
        setDataError({
          ...dataErrorRef.current,
          myPassword: "Password must be atleast 8 characters long",
        });
      } else {
        setDataError({ ...dataErrorRef.current, myPassword: "" });
      }
    }

    if (!dataRef.current.userName) {
      setDataError({
        ...dataErrorRef.current,
        userName: "Please provide the userName",
      });
    } else {
      setDataError({ ...dataErrorRef.current, userName: "" });
    }
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  };

  const getProfile = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/v1/auth/profile`, config)
      .then((res) => {
        const { email, userName } = res.data.results;
        setData({ ...data, email, userName });
      })
      .catch((err) => {
        setData([]);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await checkForm();
    const me =
      dataErrorRef.current.myPassword ||
      dataErrorRef.current.password ||
      dataErrorRef.current.userName ||
      dataErrorRef.current.email;
    if (me) {
      return;
    }

    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_API}/v1/users/update/profile`,
        {
          ...dataRef.current,
        },
        config
      )
      .then((res) => {
        // setMsg("Successfully updated your profile");
        toast.success("Successfully updated your profile");
        setOpen(true);
        setIsError(false);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setLoading(false);
          return;
        } else if (err.response.status === 500) {
          //   setMsg(Error500(err.response.data.errors[0].errors));
          toast.error(Error500(err.response.data.errors[0].errors));
          setOpen(true);
          setIsError(true);
          setLoading(false);
          return;
        } else if (err.response.status === 404) {
          //   setMsg("Wrong password");
          toast.error("Wrong password");
          setOpen(true);
          setIsError(true);
          setLoading(false);
          return;
        } else if (err.response.status === 403) {
          // setMsg(`Username ${data.userName} already taken`);
          toast.error(`Username ${data.userName} already taken`);
          setOpen(true);
          setIsError(true);
          setLoading(false);
          return;
        }

        let error;
        try {
          error = err.response.data.errors[0].error;
        } catch {
          error = "Unable to update your profile";
        }

        // setMsg(error);
        toast.error(error);
        setOpen(true);
        setIsError(true);
        setLoading(false);
      });
  };
  return (
    <>
      {/* <Row>
                <Col xs={12} md={6} xl={6}>
                    {open ? (
                        <Alert
                            variant={isError ? "danger" : "success"}
                            msg={msg}
                        />
                    ) : (
                        ""
                    )}
                </Col>
            </Row> */}
      <Row>
        <Col xs={12} md={6} xl={6}>
          <EditProfileForm
            handleSubmit={handleSubmit}
            onChangeInput={onChangeInput}
            dataError={dataErrorRef.current}
            loading={loadingRef.current}
            data={data}
          />
        </Col>
      </Row>
    </>
  );
};
