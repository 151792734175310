/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@themesberg/react-bootstrap';
import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import useState from 'react-usestateref';
import Datepicker from '../../components/Datepicker';
import { WorldCupTransactions } from '../../components/world-cup-2022';
import SelectCode from '../../components/world-cup-2022/selectCode';

export default ({ load }) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [page, setPage] = useState(1);
	const [date, setDate] = useState(new Date());
	const [code, setCode] = useState('*210*15*100#');

	React.useEffect(() => {
		getData();
	}, [page, load, refresh, date, code]);

	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.accessToken}`,
		},
	};

	const reloadTransactions = () => {
		setRefresh(!refresh);
	};

	const getData = () => {
		setLoading(true);
		axios
			.post(
				`${
					process.env.REACT_APP_BACKEND_API
				}/v1/world/cup/analysis/all?page=${page}&limit=${50}`,
				{
					name: code,
					date: new Date(date),
				},
				config
			)
			.then((res) => {
				setData(res.data.results);
				setLoading(false);
				toast.success(`Transactions for ${new Date(date).toDateString()}`);
			})
			.catch((err) => {
				setData([]);
				setLoading(false);
				toast.error('Unexpected error');
			});
	};

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<h4>Fifa World Cup 2022</h4>
				</div>
			</div>

			<div className="row">
				<div className="col-sm-2">
					<SelectCode setCode={setCode} />
				</div>
				<div className="col-sm-2">
					<Datepicker setDate={setDate} date={date} />
				</div>
				<div className="col-sm-8">
					<Button
						onClick={reloadTransactions}
						variant="white"
						className="m-1"
						style={{ fontSize: '14px', color: '#CDCCD0' }}
					>
						<i class="fas fa-sync-alt"></i> <span>Refresh</span>
					</Button>
				</div>
			</div>

			<WorldCupTransactions data={data} loading={loading} setPage={setPage} />
		</>
	);
};
