/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from '@themesberg/react-bootstrap';
import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import useState from 'react-usestateref';
import { NewCategoryForm } from '../../components/Forms';
import Alert from '../examples/alert';
import Modal from './modal';

export default ({
	showSignin,
	setShowSignin,
	edit,
	setEdit,
	setEditData,
	editData,
	id,
	resetState,
}) => {
	const [agents, setAgents] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading, loadingRef] = useState(false);
	const [msg, setMsg] = useState('');
	const [variant, setVariant] = useState('danger');
	const [data, setData, dataRef] = useState({
		name: '',
		vip: '',
		vvip: '',
		akatambula: 0,
		family: 0,
		earlykickoff: 0,
		doubles: 0,
		latekickoff: 0,
		olukalala: 0,
		singleandcorporate: 0,
		overs: 0,
		normal: 0,
		single: 0,
		odd2: 0,
		odd3: 0,
		ordinary: 0,
		supper: '',
		corporate: '',
		kibimba: '',
		agent: '',
		description: 'description',
	});
	const [dataError, setDataError, dataErrorRef] = useState({
		name: '',
		vip: '',
		vvip: '',
		akatambula: '',
		family: '',
		earlykickoff: '',
		doubles: '',
		latekickoff: '',
		olukalala: '',
		singleandcorporate: '',
		overs: '',
		normal: '',
		single: '',
		odd2: 0,
		odd3: 0,
		ordinary: '',
		supper: '',
		corporate: '',
		kibimba: '',
		agent: '',
		description: '',
	});

	React.useEffect(() => {
		getAgents();
	}, []);

	React.useEffect(() => {
		setData({ ...editData });
	}, [edit]);

	const onChangeInput = (event) => {
		setData({ ...data, [event.target.name]: event.target.value });
	};

	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.accessToken}`,
		},
	};
	const getAgents = () => {
		axios
			.get(
				`${process.env.REACT_APP_BACKEND_API}/v1/users?page=${1}&limit=${100}`,
				config
			)
			.then((res) => {
				setAgents(res.data.results);
			})
			.catch((err) => {
				setAgents([]);
			});
	};

	const checkForm = async () => {
		if (!dataRef.current.name) {
			setDataError({
				...dataErrorRef.current,
				name: 'Please provide the category name',
			});
		} else {
			setDataError({ ...dataErrorRef.current, name: '' });
		}

		if (!dataRef.current.agent || dataRef.current.agent === '0') {
			setDataError({
				...dataErrorRef.current,
				agent: 'Please select the agent',
			});
		} else {
			setDataError({ ...dataErrorRef.current, agent: '' });
		}

		if (!dataRef.current.ordinary) {
			setDataError({
				...dataErrorRef.current,
				ordinary: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.ordinary)) {
				setDataError({ ...dataErrorRef.current, ordinary: 'Invalid value' });
			} else {
				setDataError({ ...dataErrorRef.current, ordinary: '' });
			}
		}

		if (!dataRef.current.vip) {
			setDataError({
				...dataErrorRef.current,
				vip: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.vip)) {
				setDataError({ ...dataErrorRef.current, vip: 'Invalid value' });
			} else {
				setDataError({ ...dataErrorRef.current, vip: '' });
			}
		}

		if (!dataRef.current.kibimba) {
			setDataError({
				...dataErrorRef.current,
				kibimba: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.kibimba)) {
				setDataError({ ...dataErrorRef.current, kibimba: 'Invalid value' });
			} else {
				setDataError({ ...dataErrorRef.current, kibimba: '' });
			}
		}

		if (!dataRef.current.vvip) {
			setDataError({
				...dataErrorRef.current,
				vvip: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.vvip)) {
				setDataError({ ...dataErrorRef.current, vvip: 'Invalid value' });
			} else {
				setDataError({ ...dataErrorRef.current, vvip: '' });
			}
		}

		if (!dataRef.current.akatambula) {
			setDataError({
				...dataErrorRef.current,
				akatambula: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.akatambula)) {
				setDataError({ ...dataErrorRef.current, akatambula: 'Invalid value' });
			} else {
				setDataError({ ...dataErrorRef.current, akatambula: '' });
			}
		}
		if (!dataRef.current.family) {
			setDataError({
				...dataErrorRef.current,
				family: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.family)) {
				setDataError({ ...dataErrorRef.current, family: 'Invalid value' });
			} else {
				setDataError({ ...dataErrorRef.current, family: '' });
			}
		}
		if (!dataRef.current.earlykickoff) {
			setDataError({
				...dataErrorRef.current,
				earlykickoff: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.earlykickoff)) {
				setDataError({
					...dataErrorRef.current,
					earlykickoff: 'Invalid value',
				});
			} else {
				setDataError({ ...dataErrorRef.current, earlykickoff: '' });
			}
		}
		if (!dataRef.current.doubles) {
			setDataError({
				...dataErrorRef.current,
				doubles: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.doubles)) {
				setDataError({ ...dataErrorRef.current, doubles: 'Invalid value' });
			} else {
				setDataError({ ...dataErrorRef.current, doubles: '' });
			}
		}

		if (!dataRef.current.latekickoff) {
			setDataError({
				...dataErrorRef.current,
				latekickoff: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.latekickoff)) {
				setDataError({ ...dataErrorRef.current, latekickoff: 'Invalid value' });
			} else {
				setDataError({ ...dataErrorRef.current, latekickoff: '' });
			}
		}

		if (!dataRef.current.olukalala) {
			setDataError({
				...dataErrorRef.current,
				olukalala: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.olukalala)) {
				setDataError({ ...dataErrorRef.current, olukalala: 'Invalid value' });
			} else {
				setDataError({ ...dataErrorRef.current, olukalala: '' });
			}
		}
		if (!dataRef.current.singleandcorporate) {
			setDataError({
				...dataErrorRef.current,
				singleandcorporate: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.singleandcorporate)) {
				setDataError({
					...dataErrorRef.current,
					singleandcorporate: 'Invalid value',
				});
			} else {
				setDataError({ ...dataErrorRef.current, singleandcorporate: '' });
			}
		}
		if (!dataRef.current.overs) {
			setDataError({
				...dataErrorRef.current,
				overs: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.overs)) {
				setDataError({ ...dataErrorRef.current, overs: 'Invalid value' });
			} else {
				setDataError({ ...dataErrorRef.current, overs: '' });
			}
		}

		if (!dataRef.current.normal) {
			setDataError({
				...dataErrorRef.current,
				normal: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.normal)) {
				setDataError({ ...dataErrorRef.current, normal: 'Invalid value' });
			} else {
				setDataError({ ...dataErrorRef.current, normal: '' });
			}
		}

		if (!dataRef.current.single) {
			setDataError({
				...dataErrorRef.current,
				single: 'Please specify the price',
			});
		} else {
			if (isNaN(dataRef.current.single)) {
				setDataError({ ...dataErrorRef.current, single: 'Invalid value' });
			} else {
				setDataError({ ...dataErrorRef.current, single: '' });
			}
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		await checkForm();

		if (
			dataErrorRef.current.name ||
			dataErrorRef.current.agent ||
			dataErrorRef.current.ordinary ||
			dataErrorRef.current.vip ||
			dataErrorRef.current.vvip ||
			dataErrorRef.current.kibimba ||
			dataErrorRef.current.akatambula ||
			dataErrorRef.current.doubles ||
			dataErrorRef.current.latekickoff ||
			dataErrorRef.current.normal ||
			dataErrorRef.current.single ||
			dataErrorRef.current.supper ||
			dataErrorRef.current.corporate ||
			dataErrorRef.current.olukalala ||
			dataErrorRef.current.singleandcorporate ||
			dataErrorRef.current.overs
		) {
			return;
		}
		setLoading(true);

		if (!edit) {
			axios
				.post(`${process.env.REACT_APP_BACKEND_API}/v1/category`, data, config)
				.then((res) => {
					setOpen(true);
					// setMsg("Successfully created the category");
					toast.success('Successfully created the category');
					setVariant('success');
					setLoading(false);
				})
				.catch((err) => {
					if (err.response.status === 500) {
						// setMsg(
						//   `Category name ${data.name} is already taken, please try another`
						// );
						toast.error(
							`Category name ${data.name} is already taken, please try another`
						);
						setLoading(false);
					} else {
						// setMsg("Unable to create category");
						toast.error('Unable to create category');
						setLoading(false);
					}
					setVariant('danger');
					setOpen(true);
					setLoading(false);
				});
		} else {
			axios
				.patch(
					`${process.env.REACT_APP_BACKEND_API}/v1/category/${id}`,
					data,
					config
				)
				.then((res) => {
					setOpen(true);
					// setMsg("Successfully updated the category");
					toast.success('Successfully updated the category');
					setVariant('success');
					setLoading(false);
					setShowSignin(false);
					setEdit(false);
					resetState(); // clear state
				})
				.catch((err) => {
					if (err.response.status === 500) {
						// setMsg(
						//   `Category name ${data.name} is already taken, please try another`
						// );
						toast.error(
							`Category name ${data.name} is already taken, please try another`
						);
						setLoading(false);
					} else {
						// setMsg("Unable to update a category");
						console.log(err, '---err----');
						toast.error('Unable to update a category');
						setLoading(false);
					}
					setVariant('danger');
					setOpen(true);
					setLoading(false);
					resetState();
				});
		}
	};

	return (
		<>
			<Row>
				{/* <Col md={6}>
          {open ? <Alert variant={variant} msg={msg} setOpen={setOpen} /> : ""}
        </Col> */}
				<Col xs={12} xl={12}>
					<NewCategoryForm
						dataRef={dataRef}
						setData={setData}
						agents={agents.docs ? agents.docs : []}
						data={dataRef.current}
						handleSubmit={handleSubmit}
						onChangeInput={onChangeInput}
						dataError={dataError}
						loading={loadingRef.current}
					/>
				</Col>
			</Row>
			{edit && (
				<Modal
					setShowSignin={setShowSignin}
					showSignin={showSignin}
					formName={'serviceCode'}
					data={dataRef.current}
					setData={setData}
					agents={agents.docs ? agents.docs : []}
					resetState={resetState}
					handleSubmit={handleSubmit}
					onChangeInput={onChangeInput}
					dataError={dataError}
					loading={loadingRef.current}
				/>
			)}
		</>
	);
};
