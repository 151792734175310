/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import axios from "axios";
import Alert from "../examples/alert";
import useState from "react-usestateref";
import { Col, Row } from "@themesberg/react-bootstrap";
import { NewMessage } from "../../components/Forms";
import { toast } from "react-toastify";

export default () => {
  const [agents, setAgents] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading, loadingRef] = useState(false);
  const [msg, setMsg] = useState("");
  const [variant, setVariant] = useState("danger");
  const [data, setData, dataRef] = useState({
    categoryLevel: "",
    phoneNumber: "",
    message: "",
  });
  const [dataError, setDataError, dataErrorRef] = useState({
    categoryLevel: "",
    phoneNumber: "",
    message: "",
  });

  const [charsLength, setCharsLength] = useState(160);

  const onChangeInput = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    setCharsLength(160 - event.target.value.length);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  };

  const checkForm = async () => {
    if (!dataRef.current.message) {
      setDataError({
        ...dataErrorRef.current,
        message: "Please the message",
      });
    } else {
      setDataError({ ...dataErrorRef.current, message: "" });
    }

    if (
      !dataRef.current.phoneNumber ||
      dataRef.current.phoneNumber.length !== 12
    ) {
      setDataError({
        ...dataErrorRef.current,
        message: "Phone number must be in this format (256750461xxx)",
      });
    } else {
      setDataError({ ...dataErrorRef.current, phoneNumber: "" });
    }

    if (
      !dataRef.current.categoryLevel ||
      dataRef.current.categoryLevel === "0"
    ) {
      setDataError({
        ...dataErrorRef.current,
        categoryLevel: "Please select the category level",
      });
    } else {
      setDataError({ ...dataErrorRef.current, categoryLevel: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await checkForm();

    console.log(
      dataRef.current,
      "---dataRef.current---------",
      dataErrorRef.current,
      "----dataErrorRef---"
    );

    if (dataErrorRef.current.categoryLevel || dataErrorRef.current.message) {
      return;
    }
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_BACKEND_API}/v1/sms`, data, config)
      .then((res) => {
        setOpen(true);
        // setMsg("Successfully sent the message");
        toast.success("Successfully sent the message!");
        setVariant("success");
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          // setMsg(
          //   `Unable to send sms`
          // );
          setLoading(false);
        } else {
          // setMsg("Unable to send sms");
          setLoading(false);
        }
        toast.error("Unable to send sms!");
        setVariant("danger");
        setOpen(true);
        setLoading(false);
      });
  };

  return (
    <>
      <Row>
        {/* <Col md={6}>
          {open ? <Alert variant={variant} msg={msg} setOpen={setOpen} /> : ""}
        </Col> */}
        <Col xs={12} xl={12}>
          <NewMessage
            dataRef={dataRef}
            setData={setData}
            data={agents.docs ? agents.docs : []}
            handleSubmit={handleSubmit}
            onChangeInput={onChangeInput}
            dataError={dataError}
            loading={loadingRef.current}
            charsLength={charsLength}
          />
        </Col>
      </Row>
    </>
  );
};
