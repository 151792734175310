import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button } from '@themesberg/react-bootstrap';
import { NewUserForm } from "../components/Forms";
import { ProfileCardWidget } from "../components/Widgets";


export default () => {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Button variant="secondary" className="m-1">
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    <span>New user</span>
                </Button>
            </div>

            <Row>
                <Col xs={12} xl={8}>
                    <NewUserForm />
                </Col>
                <Col xs={12} xl={4}>
                    <Row>
                        <Col xs={12}>
                            <ProfileCardWidget />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

