/* eslint-disable no-unused-vars */
import {
	Button,
	Card,
	Form,
	Modal,
	Spinner,
} from '@themesberg/react-bootstrap';
import React from 'react';
import Alert from '../examples/alert';
export default ({
	showSignin,
	setShowSignin,
	handleSubmit,
	onChangeInput,
	dataError,
	agents,
	data,
	loading,
	resetState,
}) => {
	const handleClose = () => {
		setShowSignin(false);
		resetState();
	};
	return (
		<React.Fragment>
			<Modal centered show={showSignin} onHide={handleClose}>
				<Modal.Header className="border-0">
					<Button variant="close" aria-label="Close" onClick={handleClose} />
				</Modal.Header>
				<Modal.Body className="p-0">
					<Card className="px-0 px-md-4 py-0 border-0">
						<Card.Header className="pb-0 py-0 border-0">
							<h2 className="h4">Update category</h2>
							<span>You can edit the category information</span>
						</Card.Header>
						<Card.Body>
							<Form action="#" className="mt-4" onSubmit={handleSubmit}>
								<Form.Group id="firstName">
									<Form.Label>Name *</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="name"
										type="text"
										placeholder="Enter category name"
										isInvalid={dataError.name}
										value={data.name}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.name}
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group>
									<Form.Label>Ordinary *</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="ordinary"
										type="number"
										isInvalid={dataError.ordinary}
										placeholder="Enter price for this category"
										value={data.ordinary}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.ordinary}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Kibimba </Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="kibimba"
										type="number"
										isInvalid={dataError.kibimba}
										placeholder="Enter price for this category"
										value={data.kibimba}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.kibimba}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>VIP *</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="vip"
										type="number"
										isInvalid={dataError.vip}
										placeholder="Enter price for this category"
										value={data.vip}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.vip}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>VVIP *</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="vvip"
										type="number"
										isInvalid={dataError.vvip}
										placeholder="Enter price for this category"
										value={data.vvip}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.vvip}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Olukalala</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="olukalala"
										type="number"
										isInvalid={dataError.olukalala}
										placeholder="Enter price for this category"
										value={data.olukalala}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.olukalala}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Single and Corporate</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="singleandcorporate"
										type="number"
										isInvalid={dataError.singleandcorporate}
										placeholder="Enter price for this category"
										value={data.singleandcorporate}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.singleandcorporate}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Overs</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="overs"
										type="number"
										isInvalid={dataError.overs}
										placeholder="Enter price for this category"
										value={data.overs}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.overs}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Akatambula</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="akatambula"
										type="number"
										isInvalid={dataError.akatambula}
										placeholder="Enter price for this category"
										value={data.akatambula}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.akatambula}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Family</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="family"
										type="number"
										isInvalid={dataError.family}
										placeholder="Enter price for this category"
										value={data.family}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.family}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Early kickoff</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="earlykickoff"
										type="number"
										isInvalid={dataError.earlykickoff}
										placeholder="Enter price for this category"
										value={data.earlykickoff}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.earlykickoff}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Doubles</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="doubles"
										type="number"
										isInvalid={dataError.doubles}
										placeholder="Enter price for this category"
										value={data.doubles}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.doubles}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Late kickoff</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="latekickoff"
										type="number"
										isInvalid={dataError.latekickoff}
										placeholder="Enter price for this category"
										value={data.latekickoff}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.latekickoff}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Normal</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="normal"
										type="number"
										isInvalid={dataError.normal}
										placeholder="Enter price for this category"
										value={data.normal}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.normal}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Single</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="single"
										type="number"
										isInvalid={dataError.single}
										placeholder="Enter price for this category"
										value={data.single}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.single}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Odd 2</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="odd2"
										type="number"
										isInvalid={dataError.odd2}
										placeholder="Enter price for this category"
										value={data.odd2}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.odd2}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Odd 3</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="odd3"
										type="number"
										isInvalid={dataError.odd3}
										placeholder="Enter price for this category"
										value={data.odd3}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.odd3}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Super</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="supper"
										type="number"
										isInvalid={dataError.supper}
										placeholder="Enter price for this category"
										value={data.supper}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.supper}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Corporate</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="corporate"
										type="number"
										isInvalid={dataError.corporate}
										placeholder="Enter price for this category"
										value={data.corporate}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.corporate}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group id="agent">
									<Form.Label>Agent *</Form.Label>
									<Form.Select
										isInvalid={dataError.agent}
										name="agent"
										onChange={onChangeInput}
										value={data.agent}
									>
										<option value="0">--Select--</option>
										{agents.map((item) => (
											<option key={item._id} value={item._id}>
												{item.userName}
											</option>
										))}
									</Form.Select>
									<Form.Control.Feedback type="invalid">
										{dataError.agent}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Description</Form.Label>
									<Form.Control
										onChange={onChangeInput}
										name="description"
										as="textarea"
										rows="3"
										placeholder="Description....."
										value={data.description}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.description}
									</Form.Control.Feedback>
								</Form.Group>
								<div className="mt-3">
									<Button
										variant="primary"
										type="submit"
										disabled={loading}
										className="shadow-none"
									>
										Save All
										{loading ? <Spinner animation="border" size="sm" /> : ''}
									</Button>
								</div>
							</Form>
						</Card.Body>
					</Card>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
};
