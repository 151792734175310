/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Routes } from '../routes';

// pages
// import Presentation from "./Presentation";
import Account from './Account';
import Category from './Category';
import Categories from './Category/Categories';
import NewCategory from './Category/NewCategory';
import Content from './Content';
import KeyWords from './Keywords';
import Message from './Messages';
import ServiceCode from './ServiceCode';
import Transactions from './Transactions';
import FailedTransactions from './Transactions/failedTransactions';
import SearchByPhone from './Transactions/searchByPhone';
import Upgrade from './Upgrade';
import Users from './Users';
import Profile from './Users/Profile';
import DashboardOverview from './dashboard/DashboardOverview';
import ForgotPassword from './examples/ForgotPassword';
import Lock from './examples/Lock';
import NotFoundPage from './examples/NotFound';
import ResetPassword from './examples/ResetPassword';
import ServerError from './examples/ServerError';
import Signin from './examples/Signin';
import Signup from './examples/Signup';
import BootstrapTables from './tables/BootstrapTables';

// World cup 2022
import WorldCupTransactions from './World-cup-2022';
import WorldCupTransactionsFailedTransactions from './World-cup-2022/failedTransactions';
import WorldCupFinalWinner from './World-cup-2022/finalWinner';
import WorldCupGeneralRanking from './World-cup-2022/generalRakinking';
import WorldCupTransactionsSearchByPhone from './World-cup-2022/searchByPhone';
import WorldCupWeek2Ranking from './World-cup-2022/week2ranking';
import WorldCupWeek3Ranking from './World-cup-2022/week3ranking';
import WorldCupWeek4Ranking from './World-cup-2022/week4ranking';

// Afcon 2024
import Afcon2024Transactions from './Afcon-2024';
import Afcon2024TransactionsFailedTransactions from './Afcon-2024/failedTransactions';
import Afcon2024GeneralRanking from './Afcon-2024/generalRakinking';
import Afcon2024TransactionsSearchByPhone from './Afcon-2024/searchByPhone';

// documentation pages
import DocsBuild from './documentation/DocsBuild';
import DocsChangelog from './documentation/DocsChangelog';
import DocsDownload from './documentation/DocsDownload';
import DocsFolderStructure from './documentation/DocsFolderStructure';
import DocsLicense from './documentation/DocsLicense';
import DocsOverview from './documentation/DocsOverview';
import DocsQuickStart from './documentation/DocsQuickStart';

// components
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Preloader from '../components/Preloader';
import Sidebar from '../components/Sidebar';

import Accordion from './components/Accordion';
import Alerts from './components/Alerts';
import Badges from './components/Badges';
import Breadcrumbs from './components/Breadcrumbs';
import Buttons from './components/Buttons';
import Forms from './components/Forms';
import Modals from './components/Modals';
import Navbars from './components/Navbars';
import Navs from './components/Navs';
import Pagination from './components/Pagination';
import Popovers from './components/Popovers';
import Progress from './components/Progress';
import Tables from './components/Tables';
import Tabs from './components/Tabs';
import Toasts from './components/Toasts';
import Tooltips from './components/Tooltips';
const jwt = require('jsonwebtoken');

const isAuthenticated = (history, path = '/') => {
	const token = localStorage.getItem('accessToken');
	if (!token) {
		localStorage.clear();
		history.push(path);
		return;
	}
	const l = 'unwJqn5ibZOZBsdPNJU9FK8g49EXuxEEnew';
	jwt.verify(token, process.env.JWT_ACCESS_TOKEN_SECRET || l, (err, user) => {
		console.log(
			process.env.JWT_ACCESS_TOKEN_SECRET,
			'---process.env.JWT_ACCESS_TOKEN_SECRET---'
		);
		if (err) {
			localStorage.clear();
			history.push(path);
			return;
		}
	});
};

const isAlreadyAuthenticated = (history) => {
	const token = localStorage.getItem('accessToken');
	if (!token) {
		localStorage.clear();
		return;
	}
	const l = 'unwJqn5ibZOZBsdPNJU9FK8g49EXuxEEnew';
	jwt.verify(token, process.env.JWT_ACCESS_TOKEN_SECRET || l, (err, user) => {
		console.log(
			process.env.JWT_ACCESS_TOKEN_SECRET,
			'---process.env.JWT_ACCESS_TOKEN_SECRET---'
		);
		if (err) {
			localStorage.clear();
			return;
		} else {
			history.push('/transactions');
		}
	});
};

const RouteWithLoader = ({ component: Component, ...rest }) => {
	let history = useHistory();
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		isAlreadyAuthenticated(history);
		const timer = setTimeout(() => setLoaded(true), 1000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Route
			{...rest}
			render={(props) => (
				<>
					{' '}
					<Preloader show={loaded ? false : true} /> <Component {...props} />{' '}
				</>
			)}
		/>
	);
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
	let history = useHistory();
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		isAuthenticated(history);
		const timer = setTimeout(() => setLoaded(true), 1000);
		return () => clearTimeout(timer);
	}, []);

	const localStorageIsSettingsVisible = () => {
		return localStorage.getItem('settingsVisible') === 'false' ? false : true;
	};

	const [showSettings, setShowSettings] = useState(
		localStorageIsSettingsVisible
	);

	const toggleSettings = () => {
		setShowSettings(!showSettings);
		localStorage.setItem('settingsVisible', !showSettings);
	};

	return (
		<Route
			{...rest}
			render={(props) => (
				<>
					<Preloader show={loaded ? false : true} />
					<Sidebar />

					<main className="content">
						<Navbar />
						<Component {...props} />
						<Footer
							toggleSettings={toggleSettings}
							showSettings={showSettings}
						/>
					</main>
				</>
			)}
		/>
	);
};

export default () => (
	<div>
		<ToastContainer
			position="top-center"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
		/>
		<Switch>
			{/* <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} /> */}
			<RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
			<RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
			<RouteWithLoader
				exact
				path={Routes.ForgotPassword.path}
				component={ForgotPassword}
			/>
			<RouteWithLoader
				exact
				path={Routes.ResetPassword.path}
				component={ResetPassword}
			/>
			<RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
			<RouteWithLoader
				exact
				path={Routes.NotFound.path}
				component={NotFoundPage}
			/>
			<RouteWithLoader
				exact
				path={Routes.ServerError.path}
				component={ServerError}
			/>
			{/* pages */}
			<RouteWithSidebar
				exact
				path={Routes.DashboardOverview.path}
				component={DashboardOverview}
			/>
			{/* <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={Tab} /> */}
			<RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
			<RouteWithSidebar
				exact
				path={Routes.Transactions.path}
				component={Transactions}
			/>
			<RouteWithSidebar
				exact
				path={Routes.FullSearch.path}
				component={SearchByPhone}
			/>
			<RouteWithSidebar
				exact
				path={Routes.FailedTransactions.path}
				component={FailedTransactions}
			/>
			{/* Afcon 2024 --start-- */}
			<RouteWithSidebar
				exact
				path={Routes.Afcon2024Transactions.path}
				component={Afcon2024Transactions}
			/>
			<RouteWithSidebar
				exact
				path={Routes.Afcon2024Failed.path}
				component={Afcon2024TransactionsFailedTransactions}
			/>
			<RouteWithSidebar
				exact
				path={Routes.Afcon2024Search.path}
				component={Afcon2024TransactionsSearchByPhone}
			/>
			<RouteWithSidebar
				exact
				path={Routes.Afcon2024GeneralRanking.path}
				component={Afcon2024GeneralRanking}
			/>
			{/* Afcon 2024 -- end -- */}

			{/* World cup --> start */}
			<RouteWithSidebar
				exact
				path={Routes.WorldCupTransactions.path}
				component={WorldCupTransactions}
			/>
			<RouteWithSidebar
				exact
				path={Routes.WorldCupFailed.path}
				component={WorldCupTransactionsFailedTransactions}
			/>
			<RouteWithSidebar
				exact
				path={Routes.WorldCupSearch.path}
				component={WorldCupTransactionsSearchByPhone}
			/>

			<RouteWithSidebar
				exact
				path={Routes.WorldCupGeneralRanking.path}
				component={WorldCupGeneralRanking}
			/>
			<RouteWithSidebar
				exact
				path={Routes.WorldCupWeek2Ranking.path}
				component={WorldCupWeek2Ranking}
			/>
			<RouteWithSidebar
				exact
				path={Routes.WorldCupWeek3Ranking.path}
				component={WorldCupWeek3Ranking}
			/>
			<RouteWithSidebar
				exact
				path={Routes.WorldCupWeek4Ranking.path}
				component={WorldCupWeek4Ranking}
			/>
			<RouteWithSidebar
				exact
				path={Routes.WorldCupFinalWinner.path}
				component={WorldCupFinalWinner}
			/>
			{/* World cup --> End */}

			<RouteWithSidebar exact path={Routes.Settings.path} component={Profile} />
			<RouteWithSidebar exact path={Routes.Account.path} component={Account} />
			<RouteWithSidebar
				exact
				path={Routes.KeyWords.path}
				component={KeyWords}
			/>
			<RouteWithSidebar
				exact
				path={Routes.Categories.path}
				component={Categories}
			/>
			<RouteWithSidebar
				exact
				path={Routes.NewCategory.path}
				component={NewCategory}
			/>
			<RouteWithSidebar
				exact
				path={Routes.Category.path}
				component={Category}
			/>
			<RouteWithSidebar exact path={Routes.Content.path} component={Content} />
			<RouteWithSidebar exact path={Routes.Message.path} component={Message} />
			<RouteWithSidebar
				exact
				path={Routes.ServiceCode.path}
				component={ServiceCode}
			/>
			<RouteWithSidebar exact path={Routes.Users.path} component={Users} />
			<RouteWithSidebar
				exact
				path={Routes.BootstrapTables.path}
				component={BootstrapTables}
			/>
			{/* components */}
			<RouteWithSidebar
				exact
				path={Routes.Accordions.path}
				component={Accordion}
			/>
			<RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
			<RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
			<RouteWithSidebar
				exact
				path={Routes.Breadcrumbs.path}
				component={Breadcrumbs}
			/>
			<RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
			<RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
			<RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
			<RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
			<RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
			<RouteWithSidebar
				exact
				path={Routes.Pagination.path}
				component={Pagination}
			/>
			<RouteWithSidebar
				exact
				path={Routes.Popovers.path}
				component={Popovers}
			/>
			<RouteWithSidebar
				exact
				path={Routes.Progress.path}
				component={Progress}
			/>
			<RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
			<RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
			<RouteWithSidebar
				exact
				path={Routes.Tooltips.path}
				component={Tooltips}
			/>
			<RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />
			{/* documentation */}
			<RouteWithSidebar
				exact
				path={Routes.DocsOverview.path}
				component={DocsOverview}
			/>
			<RouteWithSidebar
				exact
				path={Routes.DocsDownload.path}
				component={DocsDownload}
			/>
			<RouteWithSidebar
				exact
				path={Routes.DocsQuickStart.path}
				component={DocsQuickStart}
			/>
			<RouteWithSidebar
				exact
				path={Routes.DocsLicense.path}
				component={DocsLicense}
			/>
			<RouteWithSidebar
				exact
				path={Routes.DocsFolderStructure.path}
				component={DocsFolderStructure}
			/>
			<RouteWithSidebar
				exact
				path={Routes.DocsBuild.path}
				component={DocsBuild}
			/>
			<RouteWithSidebar
				exact
				path={Routes.DocsChangelog.path}
				component={DocsChangelog}
			/>
			<Redirect to={Routes.NotFound.path} />
		</Switch>
	</div>
);
