/* eslint-disable no-unused-vars */
import React from "react";
import axios from "axios";
import useState from "react-usestateref";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import Alert from "./alert";
import { Routes } from "../../routes";

export default () => {
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState("");
  const port = window.location.port ? `:${window.location.port}` : "";
  const redirectUrl = `${window.location.protocol}//${window.location.hostname}${port}/#/reset-password`;
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading, loadingRef] = useState(false);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const send = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Invalid email");
      return;
    } else {
      setEmailError("");
    }

    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_API}/v1/auth/forgot-password`, {
        email,
        redirectUrl,
      })
      .then(() => {
        setMsg(`Please visit your email ${email} to reset your password`);
        setIsError(false);
        setOpen(true);
        setLoading(false);
      })
      .catch(() => {
        setMsg("Unable to send email");
        setIsError(true);
        setOpen(true);
        setLoading(false);
      });
  };

  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              {open ? (
                <Alert
                  variant={isError ? "danger" : "success"}
                  msg={msg}
                  setOpen={setOpen}
                />
              ) : (
                ""
              )}
            </Col>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">
                  Don't fret! Just type in your email and we will send you a
                  code to reset your password!
                </p>
                <Form onSubmit={send}>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Your Email</Form.Label>
                    <InputGroup id="email">
                      <Form.Control
                        autoFocus
                        isInvalid={emailError}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {emailError}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </div>
                  <Button
                    disabled={loadingRef.current}
                    variant="primary"
                    type="submit"
                    className="w-100 shadow-none"
                  >
                    Recover password
                    {loadingRef.current ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      ""
                    )}
                  </Button>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <p className="text-center">
                    <Card.Link
                      as={Link}
                      to={Routes.Signin.path}
                      className="text-gray-700"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="me-2" />{" "}
                      Back to sign in
                    </Card.Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
