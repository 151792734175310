/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@themesberg/react-bootstrap';
import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import useState from 'react-usestateref';
import Datepicker from '../../components/Datepicker';
import SelectAgentCode from '../../components/transactions/select.code';
import Transactions from '../../components/transactions/transactions.table';

export default ({ load }) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [page, setPage] = useState(1);
	const [date, setDate] = useState(new Date());
	const [code, setCode] = useState(localStorage.getItem('serviceCode'));

	const role = localStorage.getItem('role');
	const userName = localStorage.getItem('userName');

	const show =
		(role === 'admin' || role === 'super-admin') && userName === 'simba';

	React.useEffect(() => {
		getData();
	}, [page, load, refresh, date, code]);

	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.accessToken}`,
		},
	};

	const reloadTransactions = () => {
		setRefresh(!refresh);
	};

	const getData = () => {
		setLoading(true);
		axios
			.post(
				`${
					process.env.REACT_APP_BACKEND_API
				}/v1/transactions?page=${page}&limit=${50}`,
				{
					name: code,
					date: new Date(date),
				},
				config
			)
			.then((res) => {
				setData(res.data.results);
				setLoading(false);
				toast.success(`Transactions for ${new Date(date).toDateString()}`);
			})
			.catch((err) => {
				setData([]);
				setLoading(false);
				toast.error('Unexpected error');
			});
	};

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-0">
					<h4>Transactions</h4>
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12 col-sm-4 col-lg-2">
					<SelectAgentCode setCode={setCode} code={code} show={show} />
				</div>
				<div className="col-xs-12 col-sm-4 col-lg-2">
					<Datepicker setDate={setDate} date={date} />
				</div>

				<div className="col-xs-12 col-sm-4 col-lg-2">
					<Button
						onClick={reloadTransactions}
						variant="white"
						className="m-1"
						style={{ fontSize: '14px', color: '#CDCCD0' }}
					>
						<i class="fas fa-sync-alt"></i> <span>Refresh</span>
					</Button>
				</div>

				<div className="col-xs-12 col-sm-12 col-lg-6 row mb-4 mt-3 mb-sm-3 ">
					<div
						className={`${role === 'admin' ? 'col-sm-8' : 'col-sm-12'}`}
					>{`Transactions for ${new Date(date).toDateString()} ( ${
						data.total || 0
					} )`}</div>
					{role === 'admin' && (
						<div className="col-sm-4 text-success">{code}</div>
					)}
				</div>
			</div>

			<Transactions data={data} loading={loading} setPage={setPage} />
		</>
	);
};
