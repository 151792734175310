/* eslint-disable no-unused-vars */
import {
	Button,
	Card,
	Form,
	Modal,
	Spinner,
} from '@themesberg/react-bootstrap';
import React from 'react';
export default ({
	showSignin,
	setShowSignin,
	handleSubmit,
	onChangeInput,
	dataError,
	categories,
	data,
	loading,
	setData,
	editData,
	charsLength,
	resetState,
}) => {
	const handleClose = () => {
		setShowSignin(false);
		resetState();
	};

	return (
		<React.Fragment>
			<Modal centered show={showSignin} onHide={handleClose}>
				<Modal.Header className="border-0">
					<Button variant="close" aria-label="Close" onClick={handleClose} />
				</Modal.Header>
				<Modal.Body className="p-0">
					<Card className="px-0 px-md-4 py-0 border-0">
						<Card.Header className="pb-0 py-0 border-0">
							<h2 className="h4">Update content</h2>
							<span>You can edit your content</span>
						</Card.Header>
						<Card.Body>
							<Form action="#" className="mt-4" onSubmit={handleSubmit}>
								<Form.Group>
									<Form.Label>Text Content *</Form.Label>
									<Form.Control
										as="textarea"
										rows="3"
										name="message"
										placeholder="Text message....."
										onChange={onChangeInput}
										isInvalid={dataError.message}
										maxLength="160"
										value={data.message}
									/>
									<Form.Control.Feedback type="invalid">
										{dataError.message}
									</Form.Control.Feedback>
								</Form.Group>
								<div>
									<span style={{ fontSize: '12px' }}>{charsLength}/160</span>
									<span style={{ marginLeft: '2px' }}>Characters</span>
								</div>
								<Form.Group>
									<Form.Label>Category *</Form.Label>
									<Form.Select
										isInvalid={dataError.category}
										name="category"
										value={data.category}
										onChange={onChangeInput}
									>
										<option value="0">--Select--</option>
										{categories.map((item) => (
											<option key={item._id} value={item._id}>
												{item.name}
											</option>
										))}
									</Form.Select>
									<Form.Control.Feedback type="invalid">
										{dataError.category}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Category level *</Form.Label>
									<Form.Select
										isInvalid={dataError.categoryLevel}
										name="categoryLevel"
										value={data.categoryLevel}
										onChange={onChangeInput}
									>
										<option value="0">--Select--</option>
										<option value="ordinary">Ordinary</option>
										<option value="vip">VIP</option>
										<option value="vvip">VVIP</option>
										<option value="olukalala">Olukalala</option>
										<option value="singleandcorporate">
											Single and Corporate
										</option>
										<option value="overs">Overs</option>
										<option value="akatambula">Akatambula</option>
										<option value="family">Family</option>
										<option value="earlykickoff">Early kickoff</option>
										<option value="doubles">Doubles</option>
										<option value="latekickoff">Late Kick Off</option>
										<option value="normal">Normal</option>
										<option value="kibimba">Kibimba</option>
									</Form.Select>
									<Form.Control.Feedback type="invalid">
										{dataError.categoryLevel}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Description</Form.Label>
									<Form.Control
										value={data.description}
										onChange={onChangeInput}
										name="description"
									/>
								</Form.Group>
								<Form.Group id="activate">
									<Form.Label>Activate content</Form.Label>
									<Form.Check
										label="Activate"
										id="activate"
										disabled={editData.active}
										htmlFor="activate"
										checked={data.active}
										name="active"
										onChange={(e) =>
											setData({ ...data, active: e.currentTarget.checked })
										}
									/>
								</Form.Group>
								<div className="mt-3">
									<Button
										variant="primary"
										type="submit"
										disabled={loading}
										className="shadow-none"
									>
										Save All
										{loading ? <Spinner animation="border" size="sm" /> : ''}
									</Button>
								</div>
							</Form>
						</Card.Body>
					</Card>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
};
