/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useState from "react-usestateref";
import axios from "axios";
import { toast } from "react-toastify";

import { Categories } from "../../components/Tables";

export default ({ load, handleEditData }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  React.useEffect(() => {
    getCategories();
  }, [page, load]);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  };
  const getCategories = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/v1/category?page=${page}`,
        config
      )
      .then((res) => {
        setData(res.data.results);
        setLoading(false);
        toast.success("Categories");
      })
      .catch((err) => {
        setData([]);
        setLoading(false);
        toast.error("Unexpected error");
      });
  };

  return (
    <>
      <Categories
        handleEditData={handleEditData}
        data={data}
        loading={loading}
        setPage={setPage}
      />
    </>
  );
};
