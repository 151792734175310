import { Form } from '@themesberg/react-bootstrap';
import React from 'react';

const generateCode = () => {
	const codes = [];
	for (let i = 1; i < 26; i++) {
		codes.push(`*210*15*${i}#`);
		console.log(codes);
	}
	return codes;
};

const SelectAgentCode = ({ setCode, code, show }) => (
	<Form.Group className="mb-3">
		<Form.Select
			name="code"
			onChange={(event) => {
				setCode(event.target.value);
			}}
			defaultValue={code}
			disabled={!show}
		>
			{show &&
				generateCode().map((code) => <option value={code}>{code}</option>)}
			{!show && <option value={code}>{code}</option>}
		</Form.Select>
	</Form.Group>
);

export default SelectAgentCode;
