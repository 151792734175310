/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import axios from "axios";
import useState from "react-usestateref";
import List from "./List";
import New from "./New";
import Tabs from "../../helpers/tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default () => {
  const [showSignin, setShowSignin] = useState(false);
  const [edit, setEdit, editRef] = useState(false);
  const [load, setLoad] = useState(false);

  const [id, setID] = useState("");
  const [editData, setEditData, editDataRef] = useState({
    name: "",
    description: "",
    category: "",
  });

  const handleEditData = (data, id) => {
    setEditData({ ...data });
    setID(id);
    setEdit(true);
    setShowSignin(true);
  };

  const resetState = () => {
    setEditData({
      name: "",
    description: "",
    category: "",})
    setEdit(false);
  }

  const dataa = [
    {
      route: "All",
      component: () => <List load={load} handleEditData={handleEditData} />,
    },
    {
      route: <FontAwesomeIcon icon={faPlus} className="me-2" />,
      component: () => (
        <New
          edit={edit}
          id={id}
          setEdit={setEdit}
          editData={editDataRef.current}
          showSignin={showSignin}
          setShowSignin={setShowSignin}
          resetState={resetState}
        />
      ),
    },
  ];
  return (
    <>
      <Tabs data={dataa} load={load} setLoad={setLoad} />
    </>
  );
};
