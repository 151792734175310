import React from "react";
import { Col, Row, Nav, Tab } from "@themesberg/react-bootstrap";

export default ({ data, setLoad, load }) => {

    return (
        <>
            <Tab.Container defaultActiveKey="All">
                <Row>
                    <Col lg={12}>
                        <Nav className="nav-tabs">
                            {data.map((item, i) => (
                                <Nav.Item key={`tab-${i}`}>
                                    <Nav.Link eventKey={item.route} className="mb-sm-3 mb-md-0" onClick={() => item.route === 'All' ? setLoad(!load): ''}>
                                        {item.route}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Col>
                    <Col lg={12}>
                        <Tab.Content>
                            {data.map((item, i) => (
                                <Tab.Pane
                                    eventKey={item.route}
                                    className="py-4"
                                    key={`tab-${i}`}
                                >
                                    <item.component />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
};

export const SmsTabs = ({ data, setLoad, load }) => {

    return (
        <>
            <Tab.Container defaultActiveKey="Compose">
                <Row>
                    <Col lg={12}>
                        <Nav className="nav-tabs">
                            {data.map((item, i) => (
                                <Nav.Item key={`tab-${i}`}>
                                    <Nav.Link eventKey={item.route} className="mb-sm-3 mb-md-0" onClick={() => item.route === 'Compose' ? setLoad(!load): ''}>
                                        {item.route}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Col>
                    <Col lg={12}>
                        <Tab.Content>
                            {data.map((item, i) => (
                                <Tab.Pane
                                    eventKey={item.route}
                                    className="py-4"
                                    key={`tab-${i}`}
                                >
                                    <item.component />
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
};
