/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import useState from 'react-usestateref';
import { WorldCupSearchByPhoneTable } from '../../components/world-cup-2022';

export default ({ load }) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	React.useEffect(() => {}, [page, load]);

	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.accessToken}`,
		},
	};

	const [phone, setPhone] = useState('');

	const onChangeInput = (event) => {
		setPhone(event.target.value);
	};

	const getData = (e) => {
		e.preventDefault();
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_BACKEND_API}/v1/afcon/2024/analysis/search`,
				{ phoneNumber: phone },
				config
			)
			.then((res) => {
				setData(res.data.results);
				setLoading(false);
				toast.success('Transactions');
			})
			.catch((err) => {
				setData([]);
				setLoading(false);
				toast.error('Unexpected error');
			});
	};

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<h4>Afcon 2024 Transaction search</h4>
					<h6>Search transactions by phone number</h6>
					<p>
						<span className="text-success">Processed</span>: Transaction was
						successful
						<br /> <span className="text-warning">Pending</span>: Not yet
						confirmed <br /> <span className="text-danger">Failed </span>:
						Transactions failed. No money was deducted
					</p>
				</div>
				<form class="d-flex flex-row" onSubmit={getData}>
					<input
						class="form-control mr-sm-2"
						type="search"
						placeholder="Search"
						aria-label="Search"
						name="phone"
						value={phone}
						onChange={onChangeInput}
					/>
					<button class="btn btn-outline-dark my-2 my-sm-0" type="submit">
						Search
					</button>
				</form>
			</div>

			<WorldCupSearchByPhoneTable
				data={data}
				loading={loading}
				setPage={setPage}
			/>
		</>
	);
};
