export const Routes = {
	// pages
	// Presentation: { path: "/" },
	DashboardOverview: { path: '/dashboard' },
	Transactions: { path: '/transactions' },
	FullSearch: { path: '/search' },
	FailedTransactions: { path: '/failed' },
	Settings: { path: '/settings' },
	Account: { path: '/account' },
	KeyWords: { path: '/keywords' },
	Users: { path: '/users' },
	UsersList: { path: '/users/all' },
	Category: { path: '/category' },
	Categories: { path: '/categories/all' },
	NewCategory: { path: '/categories/new' },
	Content: { path: '/contents' },
	Message: { path: '/sms' },
	ServiceCode: { path: '/service/code' },

	// WorldCup --> start
	WorldCupTransactions: { path: '/world/cup/transactions' },
	WorldCupFailed: { path: '/world/cup/failed' },
	WorldCupSearch: { path: '/world/cup/search' },
	WorldCupGeneralRanking: { path: '/world/cup/most/general' },
	WorldCupWeek2Ranking: { path: '/world/cup/week/2/ranking' },
	WorldCupWeek3Ranking: { path: '/world/cup/week/3/ranking' },
	WorldCupWeek4Ranking: { path: '/world/cup/week/4/ranking' },
	WorldCupFinalWinner: { path: '/world/cup/final/winner' },

	// afcon 2024
	Afcon2024Transactions: { path: '/afcon/2024/transactions' },
	Afcon2024Failed: { path: '/afcon/2024/failed' },
	Afcon2024Search: { path: '/afcon/2024/search' },
	Afcon2024GeneralRanking: { path: '/afcon/2024/most/general' },

	// WorldCup --> end
	Upgrade: { path: '/upgrade' },
	BootstrapTables: { path: '/tables/bootstrap-tables' },
	Billing: { path: '/examples/billing' },
	Invoice: { path: '/examples/invoice' },
	Signin: { path: '/' },
	Signup: { path: '/examples/sign-up' },
	ForgotPassword: { path: '/forgot-password' },
	ResetPassword: { path: '/reset-password' },
	Lock: { path: '/examples/lock' },
	NotFound: { path: '/examples/404' },
	ServerError: { path: '/examples/500' },

	// docs
	DocsOverview: { path: '/documentation/overview' },
	DocsDownload: { path: '/documentation/download' },
	DocsQuickStart: { path: '/documentation/quick-start' },
	DocsLicense: { path: '/documentation/license' },
	DocsFolderStructure: { path: '/documentation/folder-structure' },
	DocsBuild: { path: '/documentation/build-tools' },
	DocsChangelog: { path: '/documentation/changelog' },

	// components
	Accordions: { path: '/components/accordions' },
	Alerts: { path: '/components/alerts' },
	Badges: { path: '/components/badges' },
	Widgets: { path: '/widgets' },
	Breadcrumbs: { path: '/components/breadcrumbs' },
	Buttons: { path: '/components/buttons' },
	Forms: { path: '/components/forms' },
	Modals: { path: '/components/modals' },
	Navs: { path: '/components/navs' },
	Navbars: { path: '/components/navbars' },
	Pagination: { path: '/components/pagination' },
	Popovers: { path: '/components/popovers' },
	Progress: { path: '/components/progress' },
	Tables: { path: '/components/tables' },
	Tabs: { path: '/components/tabs' },
	Tooltips: { path: '/components/tooltips' },
	Toasts: { path: '/components/toasts' },
	WidgetsComponent: { path: '/components/widgets' },
};
