import React from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import Alert from "./alert";
import { Routes } from "../../routes";
import useState from "react-usestateref";

export default () => {
  const [data, setData, dataRef] = useState({
    password: "",
    confirmPassword: "",
  });

  const [dataError, setDataError, dataErrorRef] = useState({
    password: "",
    confirmPassword: "",
  });

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeInput = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const check = () => {
    if (!data.password) {
      setDataError({
        ...dataErrorRef.current,
        password: "Please provide your password",
      });
    } else {
      setDataError({ ...dataErrorRef.current, password: "" });
    }

    if (!data.confirmPassword) {
      setDataError({
        ...dataErrorRef.current,
        confirmPassword: "Please provide your password",
      });
    } else {
      setDataError({ ...dataErrorRef.current, confirmPassword: "" });
    }
  };

  const complete = () => {
    if (!dataRef.confirmPassword || !dataRef.password) {
      return false;
    }
    return true;
  };

  const matchPass = () => {
    if (data.confirmPassword !== data.password) {
      setDataError({ ...dataError, confirmPassword: "Passwords don't match" });
    } else {
      setDataError({ ...dataError, confirmPassword: "" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    check();

    if (complete()) {
      return;
    }

    matchPass();

    if (complete()) {
      return;
    }
    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/v1/auth/reset-forgot-password/${params.get('token')}`,
        { password: data.password }
      )
      .then(() => {
        setIsError(false);
        setMsg("success-");
        setLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setData();
        setMsg("error-");
        setLoading(false);
      });
  };

  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              {open ? (
                <Alert
                  variant={isError ? "danger" : "success"}
                  msg={msg}
                  setOpen={setOpen}
                />
              ) : (
                ""
              )}
            </Col>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Reset password</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={onChangeInput}
                        isInvalid={dataError.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {dataErrorRef.password}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm Password"
                        onChange={onChangeInput}
                        isInvalid={dataError.confirmPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {dataErrorRef.confirmPassword}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100 shadow-none"
                    onSubmit={handleSubmit}
                  >
                    Reset password
                    {loading ? <Spinner animation="border" size="sm" /> : ""}
                  </Button>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <p className="text-center">
                    <Card.Link
                      as={Link}
                      to={Routes.Signin.path}
                      className="text-gray-700"
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="me-2" />{" "}
                      Back to sign in
                    </Card.Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
