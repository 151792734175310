/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useState from "react-usestateref";
import { Button } from "@themesberg/react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { WorldCupWeek2Ranking } from "../../components/world-cup-2022";
import UserVotesDetails from "./modals";

import SelectCode from "../../components/world-cup-2022/selectCode";

export default ({ load }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [code, setCode] = useState("*210*15*100#");
  const [limit, setLimit] = useState(50);

  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);

  const [voteDetails, setVoteDetails] = useState({});

  React.useEffect(() => {
    getData();
  }, [page, load, refresh, code]);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  };

  const reloadTransactions = () => {
    setRefresh(!refresh);
  };

  const getData = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/v1/world/cup/week2/better/all?page=${page}&limit=${limit}`,
        {
          serviceCode: code,
        },
        config
      )
      .then((res) => {
        setData(res.data.results);
        setLoading(false);
        toast.success(`User ranking by votes`);
      })
      .catch((err) => {
        setData([]);
        setLoading(false);
        toast.error("Unexpected error");
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Ranking by number of votes</h4>
        </div>
      </div>
      <UserVotesDetails
        setShowDefault={setShowDefault}
        handleClose={handleClose}
        showDefault={showDefault}
        voteDetails={voteDetails || {}}
      />

      <div className="row">
        <div className="col-sm-2">
          <SelectCode setCode={setCode} />
        </div>

        <div className="col-sm-8">
          <Button
            onClick={reloadTransactions}
            variant="white"
            className="m-1"
            style={{ fontSize: "14px", color: "#CDCCD0" }}
          >
            <i class="fas fa-sync-alt"></i> <span>Refresh</span>
          </Button>
        </div>
      </div>

      <WorldCupWeek2Ranking
        limit={limit}
        page={page}
        data={data}
        loading={loading}
        setPage={setPage}
        setShowDefault={setShowDefault}
        setVoteDetails={setVoteDetails}
      />
    </>
  );
};
