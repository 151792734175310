/* eslint-disable no-unused-vars */
import React from "react";
import useState from "react-usestateref";
import MessageList from "./Messages";
import NewMessage from "./NewMessage";
import { SmsTabs } from "../../helpers/tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

export default () => {
  const [showSignin, setShowSignin] = useState(false);
  const [edit, setEdit] = useState(false);
  const [load, setLoad] = useState(false);

  const [id, setID] = useState("");

  const data = [
    {
      route: "Compose",
      component: () => (
        <NewMessage
          id={id}
          showSignin={showSignin}
          setShowSignin={setShowSignin}
        />
      ),
    },
    {
      route: <FontAwesomeIcon icon={faList} className="me-2" />,
      component: () => <MessageList load={load} />,
    },
  ];
  return <SmsTabs data={data} load={load} setLoad={setLoad} />;
};
