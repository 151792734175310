/* eslint-disable no-unused-vars */
import moment from 'moment';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useState from 'react-usestateref';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	faAngleDown,
	faAngleUp,
	faArrowDown,
	faArrowUp,
	faCopy,
	faEdit,
	faEllipsisH,
	faExternalLinkAlt,
	faEye,
	faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
	Button,
	ButtonGroup,
	Card,
	Col,
	Dropdown,
	Image,
	Nav,
	Pagination,
	ProgressBar,
	Row,
	Table,
} from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import CurrencyFormat from 'react-currency-format';
import commands from '../data/commands';
import { pageRanking, pageTraffic, pageVisits } from '../data/tables';
import transactions from '../data/transactions';
import Empty from '../helpers/empty';
import Loader from '../helpers/loader';
import { Routes } from '../routes';

const formatAMPM = (date) => {
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'pm' : 'am';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;
	return strTime;
};

const ValueChange = ({ value, suffix }) => {
	const valueIcon = value < 0 ? faAngleDown : faAngleUp;
	const valueTxtColor = value < 0 ? 'text-danger' : 'text-success';

	return value ? (
		<span className={valueTxtColor}>
			<FontAwesomeIcon icon={valueIcon} />
			<span className="fw-bold ms-1">
				{Math.abs(value)}
				{suffix}
			</span>
		</span>
	) : (
		'--'
	);
};

export const PageVisitsTable = () => {
	const TableRow = (data) => {
		const { pageName, views, returnValue, bounceRate } = data;
		const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
		const bounceTxtColor = bounceRate < 0 ? 'text-danger' : 'text-success';

		return (
			<tr>
				<th scope="row">{pageName}</th>
				<td>{views}</td>
				<td>${returnValue}</td>
				<td>
					<FontAwesomeIcon
						icon={bounceIcon}
						className={`${bounceTxtColor} me-3`}
					/>
					{Math.abs(bounceRate)}%
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="shadow-sm">
			<Card.Header>
				<Row className="align-items-center">
					<Col>
						<h5>Page visits</h5>
					</Col>
					<Col className="text-end">
						<Button variant="secondary" size="sm">
							See all
						</Button>
					</Col>
				</Row>
			</Card.Header>
			<Table responsive className="align-items-center table-flush">
				<thead className="thead-light">
					<tr>
						<th scope="col">Page name</th>
						<th scope="col">Page Views</th>
						<th scope="col">Page Value</th>
						<th scope="col">Bounce rate</th>
					</tr>
				</thead>
				<tbody>
					{pageVisits.map((pv) => (
						<TableRow key={`page-visit-${pv.id}`} {...pv} />
					))}
				</tbody>
			</Table>
		</Card>
	);
};

export const PageTrafficTable = () => {
	const TableRow = (data) => {
		const {
			id,
			source,
			sourceIcon,
			sourceIconColor,
			sourceType,
			category,
			rank,
			trafficShare,
			change,
		} = data;

		return (
			<tr>
				<td>
					<Card.Link href="#" className="text-primary fw-bold">
						{id}
					</Card.Link>
				</td>
				<td className="fw-bold">
					<FontAwesomeIcon
						icon={sourceIcon}
						className={`icon icon-xs text-${sourceIconColor} w-30`}
					/>
					{source}
				</td>
				<td>{sourceType}</td>
				<td>{category ? category : '--'}</td>
				<td>{rank ? rank : '--'}</td>
				<td>
					<Row className="d-flex align-items-center">
						<Col xs={12} xl={2} className="px-0">
							<small className="fw-bold">{trafficShare}%</small>
						</Col>
						<Col xs={12} xl={10} className="px-0 px-xl-1">
							<ProgressBar
								variant="primary"
								className="progress-lg mb-0"
								now={trafficShare}
								min={0}
								max={100}
							/>
						</Col>
					</Row>
				</td>
				<td>
					<ValueChange value={change} suffix="%" />
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="shadow-sm mb-4">
			<Card.Body className="pb-0">
				<Table responsive className="table-centered table-nowrap rounded mb-0">
					<thead className="thead-light">
						<tr>
							<th className="border-0">#</th>
							<th className="border-0">Traffic Source</th>
							<th className="border-0">Source Type</th>
							<th className="border-0">Category</th>
							<th className="border-0">Global Rank</th>
							<th className="border-0">Traffic Share</th>
							<th className="border-0">Change</th>
						</tr>
					</thead>
					<tbody>
						{pageTraffic.map((pt) => (
							<TableRow key={`page-traffic-${pt.id}`} {...pt} />
						))}
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	);
};

export const RankingTable = () => {
	const TableRow = (data) => {
		const {
			country,
			countryImage,
			overallRank,
			overallRankChange,
			travelRank,
			travelRankChange,
			widgetsRank,
			widgetsRankChange,
		} = data;

		return (
			<tr>
				<td className="border-0">
					<Card.Link href="#" className="d-flex align-items-center">
						<Image
							src={countryImage}
							className="image-small rounded-circle me-2"
						/>
						<div>
							<span className="h6">{country}</span>
						</div>
					</Card.Link>
				</td>
				<td className="fw-bold border-0">{overallRank ? overallRank : '-'}</td>
				<td className="border-0">
					<ValueChange value={overallRankChange} />
				</td>
				<td className="fw-bold border-0">{travelRank ? travelRank : '-'}</td>
				<td className="border-0">
					<ValueChange value={travelRankChange} />
				</td>
				<td className="fw-bold border-0">{widgetsRank ? widgetsRank : '-'}</td>
				<td className="border-0">
					<ValueChange value={widgetsRankChange} />
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="shadow-sm">
			<Card.Body className="pb-0">
				<Table responsive className="table-centered table-nowrap rounded mb-0">
					<thead className="thead-light">
						<tr>
							<th className="border-0">Country</th>
							<th className="border-0">All</th>
							<th className="border-0">All Change</th>
							<th className="border-0">Travel & Local</th>
							<th className="border-0">Travel & Local Change</th>
							<th className="border-0">Widgets</th>
							<th className="border-0">Widgets Change</th>
						</tr>
					</thead>
					<tbody>
						{pageRanking.map((r) => (
							<TableRow key={`ranking-${r.id}`} {...r} />
						))}
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	);
};

export const TransactionsTable = () => {
	const totalRecords = transactions.length;

	const TableRow = (data) => {
		const { invoiceNumber, subscription, price, issueDate, dueDate, status } =
			data;
		const statusVariant =
			status === 'Paid'
				? 'success'
				: status === 'Due'
				? 'warning'
				: status === 'Canceled'
				? 'danger'
				: 'primary';

		return (
			<tr>
				<td>
					<Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
						{invoiceNumber}
					</Card.Link>
				</td>
				<td>
					<span className="fw-normal">{subscription}</span>
				</td>
				<td>
					<span className="fw-normal">{issueDate}</span>
				</td>
				<td>
					<span className="fw-normal">{dueDate}</span>
				</td>
				<td>
					<span className="fw-normal">${parseFloat(price).toFixed(2)}</span>
				</td>
				<td>
					<span className={`fw-normal text-${statusVariant}`}>{status}</span>
				</td>
				<td>
					<Dropdown as={ButtonGroup}>
						<Dropdown.Toggle
							as={Button}
							split
							variant="link"
							className="text-dark m-0 p-0"
						>
							<span className="icon icon-sm">
								<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
							</span>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item>
								<FontAwesomeIcon icon={faEye} className="me-2" /> View Details
							</Dropdown.Item>
							<Dropdown.Item>
								<FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
							</Dropdown.Item>
							<Dropdown.Item className="text-danger">
								<FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Bill For</th>
							<th className="border-bottom">Issue Date</th>
							<th className="border-bottom">Due Date</th>
							<th className="border-bottom">Total</th>
							<th className="border-bottom">Status</th>
							<th className="border-bottom">Action</th>
						</tr>
					</thead>
					<tbody>
						{transactions.map((t) => (
							<TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
						))}
					</tbody>
				</Table>
				<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
					<Nav>
						<Pagination className="mb-2 mb-lg-0">
							<Pagination.Prev>Previous</Pagination.Prev>
							<Pagination.Item active>1</Pagination.Item>
							<Pagination.Item>2</Pagination.Item>
							<Pagination.Item>3</Pagination.Item>
							<Pagination.Item>4</Pagination.Item>
							<Pagination.Item>5</Pagination.Item>
							<Pagination.Next>Next</Pagination.Next>
						</Pagination>
					</Nav>
					<small className="fw-bold">
						Showing <b>{totalRecords}</b> out of <b>25</b> entries
					</small>
				</Card.Footer>
			</Card.Body>
		</Card>
	);
};

export const Transactions = ({ loading, setPage, data }) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const tableStyle = {
		// height: "10px",
		// padding: "3px"
	};

	const TableRow = (data) => {
		const {
			isComplete,
			category,
			shortCode,
			phoneNumber,
			network,
			createdAt,
			callbackTime,
			no,
			isDeleted,
			categoryLevel,
		} = data;

		let statusVariant;
		let statusText;
		if (isComplete) {
			statusVariant = 'success';
			statusText = 'processed';
		} else {
			if (isDeleted) {
				statusVariant = 'danger';
				statusText = 'failed';
			} else {
				statusVariant = 'warning';
				statusText = 'pending';
			}
		}

		return (
			<tr style={tableStyle}>
				<td>
					<Card.Link className="fw-normal">{no}</Card.Link>
				</td>
				<td>
					<span className="fw-normal">
						{`${moment(callbackTime).format('DD MMMM YYYY')} ${formatAMPM(
							new Date(callbackTime)
						)}`}
					</span>
				</td>
				<td>
					<span className="fw-normal">{shortCode.name}</span>
				</td>

				{/* <td>
          <span className="fw-normal">
            {phoneNumber.slice(0, phoneNumber.length - 3) + "***"}
          </span>
        </td> */}
				<td>
					<span className="fw-normal">{phoneNumber}</span>
				</td>
				<td>
					<span className="fw-normal">{categoryLevel}</span>
				</td>

				<td>
					<span className={`fw-normal text-${statusVariant}`}>
						{statusText}
					</span>
				</td>
				<td>
					<span className="fw-normal">{network}</span>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr style={tableStyle}>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Date/Time</th>
							<th className="border-bottom">Service code</th>
							<th className="border-bottom">From</th>
							<th className="border-bottom">Category</th>
							<th className="border-bottom">Status</th>
							<th className="border-bottom">Network</th>
						</tr>
					</thead>
					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`trans-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const FaliledTransactions = ({
	loading,
	setPage,
	data,
	resolveTransaction,
	clickedButton,
}) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const tableStyle = {
		// height: "10px",
		// padding: "3px"
	};

	const TableRow = (data) => {
		const {
			isComplete,
			category,
			shortCode,
			phoneNumber,
			network,
			createdAt,
			callbackTime,
			no,
			isDeleted,
			categoryLevel,
			isResolved,
			_id,
		} = data;

		let statusVariant;
		let statusText;

		if (isComplete) {
			statusVariant = 'success';
			statusText = 'processed';
		} else {
			if (isDeleted) {
				statusVariant = 'danger';
				statusText = 'failed';
			} else {
				statusVariant = 'warning';
				statusText = 'pending';
			}
		}

		return (
			<tr style={tableStyle}>
				<td>
					<Card.Link className="fw-normal">{no}</Card.Link>
				</td>
				<td>
					<span className="fw-normal">
						{`${moment(createdAt).format('DD MMMM YYYY')} ${formatAMPM(
							new Date(createdAt)
						)}`}
					</span>
				</td>
				<td>
					{!callbackTime ? (
						<span>N/A</span>
					) : (
						<span className="fw-normal">
							{`${moment(callbackTime).format('DD MMMM YYYY')} ${formatAMPM(
								new Date(callbackTime)
							)}`}
						</span>
					)}
				</td>
				<td>
					<span className="fw-normal">{shortCode.name}</span>
				</td>

				{/* <td>
          <span className="fw-normal">
            {phoneNumber.slice(0, phoneNumber.length - 3) + "***"}
          </span>
        </td> */}
				<td>
					<span className="fw-normal">{phoneNumber}</span>
				</td>
				<td>
					<span className="fw-normal">{categoryLevel}</span>
				</td>

				<td>
					<span className={`fw-normal text-${statusVariant}`}>
						{statusText}
					</span>
				</td>
				<td>
					<span className="fw-normal">{network}</span>
				</td>
				<td>
					<span
						className={`fw-normal text-${
							isResolved || clickedButton.includes(_id) ? 'success' : 'warning'
						}`}
					>
						{isResolved || clickedButton.includes(_id) ? 'Yes' : 'No'}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						<button
							onClick={() => resolveTransaction(_id)}
							className={`btn ${
								isResolved || clickedButton.includes(_id)
									? 'btn-outline-success disabled'
									: 'btn-outline-warning'
							} `}
						>
							{isResolved || clickedButton.includes(_id)
								? 'Resolved'
								: 'Resolve'}
						</button>
					</span>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr style={tableStyle}>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Call Time</th>
							<th className="border-bottom">Response Time</th>
							<th className="border-bottom">Service code</th>
							<th className="border-bottom">From</th>
							<th className="border-bottom">Category</th>
							<th className="border-bottom">Status</th>
							<th className="border-bottom">Network</th>
							<th className="border-bottom">Resolved</th>
							<th className="border-bottom">Action</th>
						</tr>
					</thead>
					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`trans-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const SearchByPhoneTable = ({ loading, setPage, data }) => {
	const [copy, setCopy] = useState({ copied: false, id: '' });

	const handleCopy = (id) => {
		setCopy({ ...copy, copied: true, id });
	};
	const dataList = data ? data : [];
	const totalRecords = dataList.length;

	const tableStyle = {
		// height: "10px",
		// padding: "3px"
	};

	const TableRow = (data) => {
		const {
			isComplete,
			category,
			shortCode,
			_id,
			name,
			phoneNumber,
			messageStatuses,
			network,
			createdAt,
			callbackTime,
			no,
			isDeleted,
			categoryLevel,
			ticket,
		} = data;

		let statusVariant;
		let statusText;
		if (isComplete) {
			statusVariant = 'success';
			statusText = 'processed';
		} else {
			if (isDeleted) {
				statusVariant = 'danger';
				statusText = 'failed';
			} else {
				statusVariant = 'warning';
				statusText = 'pending';
			}
		}

		return (
			<tr style={tableStyle}>
				<td>
					<Card.Link className="fw-normal">{no}</Card.Link>
				</td>
				<td>
					<span className="fw-normal">
						{`${moment(createdAt).format('DD MMMM YYYY')} ${formatAMPM(
							new Date(createdAt)
						)}`}
					</span>
				</td>
				<td>
					{!callbackTime ? (
						<span>N/A</span>
					) : (
						<span className="fw-normal">
							{`${moment(callbackTime).format('DD MMMM YYYY')} ${formatAMPM(
								new Date(callbackTime)
							)}`}
						</span>
					)}
				</td>
				<td>
					<span className="fw-normal">{name}</span>
				</td>

				{/* <td>
          <span className="fw-normal">
            {phoneNumber.slice(0, phoneNumber.length - 3) + "***"}
          </span>
        </td> */}
				<td>
					<span className="fw-normal">{phoneNumber}</span>
				</td>
				<td>
					<span className="fw-normal">
						<CopyToClipboard
							text={ticket.message}
							onCopy={() => handleCopy(_id)}
						>
							<button className="btn btn-light">
								Ticket{' '}
								<FontAwesomeIcon
									className={`fa-light ${
										copy?.id === _id ? 'text-success' : 'text-muted'
									}`}
									icon={faCopy}
								/>{' '}
							</button>
						</CopyToClipboard>
					</span>
				</td>
				<td>
					<span className="fw-normal">{categoryLevel}</span>
				</td>

				<td>
					<span className={`fw-normal text-${statusVariant}`}>
						{statusText}
					</span>
				</td>
				<td>
					<span className={`fw-normal text-${statusVariant}`}>
						{messageStatuses}
					</span>
				</td>
				<td>
					<span className="fw-normal">{network}</span>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr style={tableStyle}>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Call time</th>
							<th className="border-bottom">Response time</th>
							<th className="border-bottom">Service code</th>
							<th className="border-bottom">From</th>
							<th className="border-bottom">Copy</th>
							<th className="border-bottom">Category</th>
							<th className="border-bottom">Status</th>
							<th className="border-bottom">Message</th>
							<th className="border-bottom">Network</th>
						</tr>
					</thead>
					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`trans-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const UsersTable = ({ data, loading, setPage, handleEditData }) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const TableRow = (data) => {
		const { no, role, userName, createdAt, password, _id } = data;

		return (
			<tr>
				<td>
					<Card.Link as={Link} to={'#'} className="fw-normal">
						{no}
					</Card.Link>
				</td>
				<td>
					<span className="fw-normal">{userName}</span>
				</td>
				<td>
					<span className="fw-normal">
						{`${moment(createdAt).format('DD MMMM YYYY')} ${formatAMPM(
							new Date(createdAt)
						)}`}
					</span>
				</td>
				<td>
					<span className="fw-normal">{role}</span>
				</td>
				<td>
					<Button
						variant="light"
						disabled={role === 'admin' || role === 'super-user'}
						size="sm"
						className="me-1"
						onClick={(e) => handleEditData({ userName, role, password }, _id)}
					>
						<FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
					</Button>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Username</th>
							<th className="border-bottom">Created</th>
							<th className="border-bottom">Role</th>
							<th className="border-bottom">Action</th>
						</tr>
					</thead>
					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`users-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const CommandsTable = () => {
	const TableRow = (data) => {
		const { name, usage = [], description, link } = data;

		return (
			<tr>
				<td className="border-0" style={{ width: '5%' }}>
					<code>{name}</code>
				</td>
				<td className="fw-bold border-0" style={{ width: '5%' }}>
					<ul className="ps-0">
						{usage.map((u) => (
							<ol key={u} className="ps-0">
								<code>{u}</code>
							</ol>
						))}
					</ul>
				</td>
				<td className="border-0" style={{ width: '50%' }}>
					<pre className="m-0 p-0">{description}</pre>
				</td>
				<td className="border-0" style={{ width: '40%' }}>
					<pre>
						<Card.Link href={link} target="_blank">
							Read More{' '}
							<FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
						</Card.Link>
					</pre>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="shadow-sm">
			<Card.Body className="p-0">
				<Table
					responsive
					className="table-centered rounded"
					style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
				>
					<thead className="thead-light">
						<tr>
							<th className="border-0" style={{ width: '5%' }}>
								Name
							</th>
							<th className="border-0" style={{ width: '5%' }}>
								Usage
							</th>
							<th className="border-0" style={{ width: '50%' }}>
								Description
							</th>
							<th className="border-0" style={{ width: '40%' }}>
								Extra
							</th>
						</tr>
					</thead>
					<tbody>
						{commands.map((c) => (
							<TableRow key={`command-${c.id}`} {...c} />
						))}
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	);
};

export const Categories = ({ data, loading, setPage, handleEditData }) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const TableRow = (data) => {
		const {
			no,
			name,
			createdAt,
			ordinary,
			kibimba,
			vip,
			vvip,
			akatambula,
			family,
			earlykickoff,
			doubles,
			latekickoff,
			olukalala,
			singleandcorporate,
			overs,
			normal,
			single,
			odd2,
			odd3,
			supper,
			corporate,
			user,
			description,
			_id,
		} = data;

		return (
			<tr>
				<td>
					<Card.Link as={Link} className="fw-normal">
						{no}
					</Card.Link>
				</td>
				<td>
					<span className="fw-normal">{name}</span>
				</td>
				<td>
					<span className="fw-normal">
						<CurrencyFormat
							value={ordinary}
							displayType={'text'}
							thousandSeparator={true}
							prefix={''}
						/>
					</span>
				</td>
				<td>
					<span className="fw-normal">
						<CurrencyFormat
							value={vip}
							displayType={'text'}
							thousandSeparator={true}
							prefix={''}
						/>
					</span>
				</td>
				<td>
					<span className="fw-normal">
						<CurrencyFormat
							value={vvip}
							displayType={'text'}
							thousandSeparator={true}
							prefix={''}
						/>
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!akatambula ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={akatambula}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!family ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={family}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!earlykickoff ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={earlykickoff}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!doubles ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={doubles}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!latekickoff ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={latekickoff}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!olukalala ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={olukalala}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!singleandcorporate ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={singleandcorporate}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!overs ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={overs}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!normal ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={normal}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!single ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={single}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!odd2 ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={odd2}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!odd3 ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={odd3}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!supper ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={supper}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{!corporate ? (
							<span>N/A</span>
						) : (
							<CurrencyFormat
								value={corporate}
								displayType={'text'}
								thousandSeparator={true}
								prefix={''}
							/>
						)}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						<CurrencyFormat
							value={kibimba}
							displayType={'text'}
							thousandSeparator={true}
							prefix={''}
						/>
					</span>
				</td>
				<td>
					<span className="fw-normal">{user.userName}</span>
				</td>
				<td>
					<span className="fw-normal">
						{`${moment(createdAt).format('DD MMMM YYYY')} ${formatAMPM(
							new Date(createdAt)
						)}`}
					</span>
				</td>

				<td>
					<Button
						variant="light"
						size="sm"
						className="me-1"
						onClick={(e) =>
							handleEditData(
								{
									name,
									ordinary,
									kibimba,
									vip,
									vvip,
									akatambula,
									doubles,
									latekickoff,
									olukalala,
									singleandcorporate,
									overs,
									normal,
									single,
									odd2,
									odd3,
									supper,
									corporate,
									agent: user._id,
									description,
								},
								_id
							)
						}
					>
						<FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
					</Button>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Name</th>
							<th className="border-bottom">Ordinary</th>
							<th className="border-bottom">vip</th>
							<th className="border-bottom">vvip</th>
							<th className="border-bottom">Akatambula</th>
							<th className="border-bottom">Family</th>
							<th className="border-bottom">Early kickoff</th>
							<th className="border-bottom">Doubles</th>
							<th className="border-bottom">Late Kickoff</th>
							<th className="border-bottom">Olukalala</th>
							<th className="border-bottom">Single and Corporate</th>
							<th className="border-bottom">Overs</th>
							<th className="border-bottom">Normal</th>
							<th className="border-bottom">Single</th>
							<th className="border-bottom">Odd 2</th>
							<th className="border-bottom">Odd 3</th>
							<th className="border-bottom">Super</th>
							<th className="border-bottom">Corporate</th>
							<th className="border-bottom">Kibimba</th>
							<th className="border-bottom">Agent</th>
							<th className="border-bottom">Created_at</th>
							<th className="border-bottom">Action</th>
						</tr>
					</thead>

					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`users-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const Messages = ({ data, loading, setPage }) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const TableRow = (data) => {
		const { no, createdAt, user, phoneNumber, message, categoryLevel } = data;

		return (
			<tr>
				<td>
					<Card.Link as={Link} className="fw-normal">
						{no}
					</Card.Link>
				</td>
				<td>
					<span className="fw-normal">{user.userName}</span>
				</td>
				<td>
					<span className="fw-normal">
						{`${moment(createdAt).format('DD MMMM YYYY')} ${formatAMPM(
							new Date(createdAt)
						)}`}
					</span>
				</td>
				<td>
					<span className="fw-normal">{phoneNumber}</span>
				</td>
				<td>
					<span className="fw-normal">{message}</span>
				</td>
				<td>
					<span className="fw-normal">{categoryLevel}</span>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">#</th>
							<th className="border-bottom">By</th>
							<th className="border-bottom">Created_at</th>
							<th className="border-bottom">phoneNumber</th>
							<th className="border-bottom">message</th>
							<th className="border-bottom">category Level</th>
						</tr>
					</thead>

					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`users-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const ServiceCodeTable = ({
	data,
	loading,
	setPage,
	setShowSignin,
	handleEditData,
}) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const TableRow = (data) => {
		const { no, name, createdAt, category, description, _id } = data;

		return (
			<tr>
				<td>
					<Card.Link as={Link} className="fw-normal">
						{no}
					</Card.Link>
				</td>
				<td>
					<span className="fw-normal">{name}</span>
				</td>

				<td>
					<span className="fw-normal">{category ? category.name : ''}</span>
				</td>
				<td>
					<span className="fw-normal">
						{category ? category.user.userName : ''}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{`${moment(createdAt).format('DD MMMM YYYY')} ${formatAMPM(
							new Date(createdAt)
						)}`}
					</span>
				</td>

				<td>
					<Button
						variant="light"
						size="sm"
						className="me-1"
						onClick={(e) =>
							handleEditData({ name, category: category._id, description }, _id)
						}
					>
						<FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
					</Button>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Code</th>
							<th className="border-bottom">Category</th>
							<th className="border-bottom">Agent</th>
							<th className="border-bottom">Created_at</th>
							<th className="border-bottom">Action</th>
						</tr>
					</thead>

					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`users-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export const ContentTable = ({ data, loading, setPage, handleEditData }) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const TableRow = (data) => {
		const {
			no,
			createdAt,
			active,
			createdBy,
			category,
			message,
			_id,
			name,
			description,
			categoryLevel,
		} = data;

		const statusVariant = active ? 'success' : 'warning';

		return (
			<tr>
				<td>
					<Card.Link as={Link} className="fw-normal">
						{no}
					</Card.Link>
				</td>
				<td>
					<span className="fw-normal">
						{`${moment(createdAt).format('DD MMMM YYYY')} ${formatAMPM(
							new Date(createdAt)
						)}`}
					</span>
				</td>
				<td>
					<span className="fw-normal">{createdBy.userName}</span>
				</td>
				<td>
					<span className="fw-normal">{category.name}</span>
				</td>
				<td>
					<span className="fw-normal">{categoryLevel}</span>
				</td>
				<td>
					<span
						className="fw-normal"
						// style={{
						//   wordWrap: "break-word",
						//   minWidth: "20px",
						//   maxWidth: "50px",
						// }}
					>
						{`${message.slice(0, 100)}`}
						{message.length > 100 ? '....' : ''}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{description ? description.slice(0, 20) : 'N/A'}
					</span>
				</td>
				<td>
					<span className={`fw-normal text-${statusVariant}`}>
						{`${active ? 'active' : 'in-active'}`}
					</span>
				</td>

				<td>
					<Button
						variant="light"
						size="sm"
						className="me-1"
						onClick={(e) =>
							handleEditData(
								{
									name,
									category: category._id,
									categoryLevel,
									message,
									description,
									active,
								},
								_id
							)
						}
					>
						<FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
					</Button>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Created_at</th>
							<th className="border-bottom">Created By</th>
							<th className="border-bottom">Category</th>
							<th className="border-bottom">Class</th>
							<th
								className="border-bottom"
								// style={{
								//   wordWrap: "break-word",
								//   minWidth: "20px",
								//   maxWidth: "50px",
								// }}
							>
								message
							</th>
							<th className="border-bottom">Description</th>
							<th className="border-bottom">Status</th>
							<th className="border-bottom">Action</th>
						</tr>
					</thead>

					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`users-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};
