import React from 'react';
import { Alert, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";

function DismissableAlerts(props) {
    const [hiddenAlerts, setHiddenAlerts] = React.useState([]);
  
    const onClose = (alertId) => {
      const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
      setHiddenAlerts(hiddenAlertsUpdated);
    };
  
    const shouldShowAlert = (alertId) => (
      hiddenAlerts.indexOf(alertId) === -1
    );
    
    const { variant, msg, setOpen } = props;
    let variants = variant || 'primary';
    return(
      <React.Fragment>
        <Alert
          variant={variants}
          show={shouldShowAlert(variants)}
          onClose={() => onClose(variants)}>
  
          <div className="d-flex justify-content-between">
            <div className="text-center">
              <FontAwesomeIcon icon={faBullhorn} className="me-1" />
              <strong>Feedback</strong> {msg}
            </div>
            <Button variant="close" size="xs" onClick={() => {
                onClose(variants)
                setOpen(false);
                }
                } />
          </div>
        </Alert>
      </React.Fragment>
    );
  }
  
 export default DismissableAlerts;
