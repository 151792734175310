import { Card, Nav, Pagination, Table } from '@themesberg/react-bootstrap';
import moment from 'moment';
import React from 'react';
import Empty from '../../helpers/empty';
import Loader from '../../helpers/loader';

const formatAMPM = (date) => {
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'pm' : 'am';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;
	return strTime;
};

const Transactions = ({ loading, setPage, data }) => {
	const dataList = data.docs ? data.docs : [];
	const totalRecords = dataList.length;

	const TableRow = (data) => {
		const {
			isComplete,
			shortCode,
			phoneNumber,
			network,
			callbackTime,
			no,
			isDeleted,
			categoryLevel,
		} = data;

		let statusVariant;
		let statusText;
		if (isComplete) {
			statusVariant = 'success';
			statusText = 'processed';
		} else {
			if (isDeleted) {
				statusVariant = 'danger';
				statusText = 'failed';
			} else {
				statusVariant = 'warning';
				statusText = 'pending';
			}
		}

		return (
			<tr>
				<td>
					<Card.Link className="fw-normal">{no}</Card.Link>
				</td>
				<td>
					<span className="fw-normal">
						{`${moment(callbackTime).format('DD MMMM YYYY')} ${formatAMPM(
							new Date(callbackTime)
						)}`}
					</span>
				</td>
				<td>
					<span className="fw-normal">{shortCode.name}</span>
				</td>

				<td>
					<span className="fw-normal">{phoneNumber}</span>
				</td>
				<td>
					<span className="fw-normal">{categoryLevel}</span>
				</td>

				<td>
					<span className={`fw-normal text-${statusVariant}`}>
						{statusText}
					</span>
				</td>
				<td>
					<span className="fw-normal">{network}</span>
				</td>
			</tr>
		);
	};

	return (
		<Card border="light" className="table-wrapper table-responsive shadow-sm">
			<Card.Body className="pt-0">
				<Table hover className="user-table align-items-center">
					<thead>
						<tr>
							<th className="border-bottom">#</th>
							<th className="border-bottom">Date/Time</th>
							<th className="border-bottom">Service code</th>
							<th className="border-bottom">From</th>
							<th className="border-bottom">Category</th>
							<th className="border-bottom">Status</th>
							<th className="border-bottom">Network</th>
						</tr>
					</thead>
					<tbody>
						{loading && (
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<Loader />
								</td>
							</tr>
						)}
						{!loading && dataList.length === 0 ? (
							<Empty />
						) : (
							dataList.map((t, i) => (
								<TableRow no={i + 1} key={`trans-${t._id}`} {...t} />
							))
						)}
					</tbody>
				</Table>
				{!data.page ? (
					''
				) : (
					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev
									disabled={data.previous ? false : true}
									onClick={() => setPage(data.previous)}
								>
									Previous
								</Pagination.Prev>
								<Pagination.Item active>{data.page}</Pagination.Item>
								<Pagination.Next
									disabled={data.next ? false : true}
									onClick={() => setPage(data.next)}
								>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>
						<small className="fw-bold">
							Showing <b>{totalRecords}</b> out of <b>{data.total}</b> entries
						</small>
					</Card.Footer>
				)}
			</Card.Body>
		</Card>
	);
};

export default Transactions;
