/* eslint-disable no-unused-vars */
import React from "react";
import {
    Card,
    Form,
    Button,
    Modal,
    Spinner,
} from "@themesberg/react-bootstrap";
import Alert from "../examples/alert";
export default ({
    showSignin,
    setShowSignin,
    handleSubmit,
    onChangeInput,
    dataError,
    data,
    categories,
    loading,
    resetState,
}) => {
    const handleClose = () => {
        setShowSignin(false);
        resetState();
    }
    return (
        <React.Fragment>
            {/* {open ? <Alert variant={variant} msg={msg} setOpen={setOpen} /> : ""} */}

            <Modal centered show={showSignin} onHide={handleClose}>
                <Modal.Header className="border-0">
                    <Button variant="close" aria-label="Close" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Card className="px-0 px-md-4 py-0 border-0">
                        <Card.Header className="pb-0 py-0 border-0">
                            <h2 className="h4">Update service code</h2>
                            <span>You can edit the service code</span>
                        </Card.Header>
                        <Card.Body>
                            <Form action="#" className="mt-4" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Name *</Form.Label>
                                    <Form.Control
                                        onChange={onChangeInput}
                                        name="name"
                                        type="text"
                                        placeholder="Enter code"
                                        isInvalid={dataError.name}
                                        value={data.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {dataError.name}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Category *</Form.Label>
                                    <Form.Select
                                        isInvalid={dataError.category}
                                        name="category"
                                        onChange={onChangeInput}
                                        value={data.category}
                                    >
                                        <option value="0">--Select--</option>
                                        {categories.map((item) => (
                                            <option key={item._id} value={item._id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {dataError.category}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        onChange={onChangeInput}
                                        name="description"
                                        as="textarea"
                                        rows="3"
                                        placeholder="Description....."
                                        value={data.description}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {dataError.description}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="mt-3">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        disabled={loading}
                                        className="shadow-none"
                                    >
                                        Save All
                                        {loading ? <Spinner animation="border" size="sm" /> : ""}
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};
