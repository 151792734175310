/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import axios from "axios";
import { Col, Row } from "@themesberg/react-bootstrap";
import { NewContentForm } from "../../components/Forms";
import useState from "react-usestateref";
import Alert from "../examples/alert";
import Modal from "./modal";
import { toast } from "react-toastify";

export default ({
  showSignin,
  setShowSignin,
  edit,
  setEdit,
  editData,
  id,
  resetState,
}) => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading, loadingRef] = useState(false);
  const [msg, setMsg] = useState("");
  const [variant, setVariant] = useState("danger");
  const [data, setData, dataRef] = useState({
    name: "",
    description: "",
    category: "",
    categoryLevel: "",
    message: "",
    active: false,
  });
  const [dataError, setDataError, dataErrorRef] = useState({
    name: "",
    description: "",
    category: "",
    categoryLevel: "",
    message: "",
    active: false,
  });

  const [charsLength, setCharsLength] = useState(160);

  React.useEffect(() => {
    getcategories();
  }, []);

  React.useEffect(() => {
    setData({ ...editData });
    setCharsLength(editData.message.length);
  }, [edit]);

  const onChangeInput = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    setCharsLength(160 - event.target.value.length);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.accessToken}`,
    },
  };
  const getcategories = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_BACKEND_API
        }/v1/category?page=${1}&limit=${100}`,
        config
      )
      .then((res) => {
        setCategories(res.data.results);
      })
      .catch((err) => {
        setCategories([]);
      });
  };

  const checkForm = async () => {
    if (!dataRef.current.category || dataRef.current.category === "0") {
      setDataError({
        ...dataErrorRef.current,
        category: "Please select the category",
      });
    } else {
      setDataError({ ...dataErrorRef.current, category: "" });
    }

    if (!dataRef.current.message) {
      setDataError({
        ...dataErrorRef.current,
        message: "Please the message",
      });
    } else {
      setDataError({ ...dataErrorRef.current, message: "" });
    }

    if (
      !dataRef.current.categoryLevel ||
      dataRef.current.categoryLevel === "0"
    ) {
      setDataError({
        ...dataErrorRef.current,
        categoryLevel: "Please select the category level",
      });
    } else {
      setDataError({ ...dataErrorRef.current, categoryLevel: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await checkForm();

    console.log(
      dataRef.current,
      "---dataRef.current---------",
      dataErrorRef.current,
      "----dataErrorRef---"
    );

    if (
      dataErrorRef.current.category ||
      dataErrorRef.current.categoryLevel ||
      dataErrorRef.current.message
    ) {
      return;
    }
    setLoading(true);

    if (!edit) {
      console.log(edit, "---edit--------if----");
      axios
        .post(`${process.env.REACT_APP_BACKEND_API}/v1/ticket`, data, config)
        .then((res) => {
          setOpen(true);
          // setMsg("Successfully created a ticket");
          toast.success("Successfully created a ticket");
          setVariant("success");
          setLoading(false);
        })
        .catch((err) => {
          // setMsg("Unable to create a ticket");
          toast.success("Unable to create a ticket");
          setVariant("danger");
          setOpen(true);
          setLoading(false);
        });
    } else {
      console.log(edit, "---edit-------else--");
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_API}/v1/ticket/${id}`,
          data,
          config
        )
        .then((res) => {
          setOpen(true);
          // setMsg("Successfully updated a ticket");
          toast.success("Unable to updated a ticket");
          setVariant("success");
          setLoading(false);
          setShowSignin(false);
          resetState();
        })
        .catch((err) => {
          // setMsg("Unable to update a ticket");
          toast.success("Unable to update a ticket");
          setVariant("danger");
          setOpen(true);
          setLoading(false);
          setShowSignin(false);
          resetState();
        });
    }
  };
  return (
    <>
      <Row>
        {/* <Col md={6}>
          {open ? <Alert variant={variant} msg={msg} setOpen={setOpen} /> : ""}
        </Col> */}
        <Col xs={12} xl={12}>
          <NewContentForm
            dataRef={dataRef}
            setData={setData}
            data={data}
            categories={categories.docs ? categories.docs : []}
            handleSubmit={handleSubmit}
            onChangeInput={onChangeInput}
            dataError={dataError}
            loading={loadingRef.current}
            charsLength={charsLength}
          />
        </Col>
      </Row>
      {edit && (
        <Modal
          setShowSignin={setShowSignin}
          resetState={resetState}
          showSignin={showSignin}
          formName={"serviceCode"}
          data={dataRef.current}
          categories={categories.docs ? categories.docs : []}
          setData={setData}
          editData={editData}
          handleSubmit={handleSubmit}
          onChangeInput={onChangeInput}
          dataError={dataError}
          loading={loadingRef.current}
          charsLength={charsLength}
        />
      )}
    </>
  );
};
