/* eslint-disable no-unused-vars */

import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Nav, Navbar } from '@themesberg/react-bootstrap';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

export default (props) => {
	let history = useHistory();
	const handleLogout = () => {
		toast.success('Logged out');
		localStorage.clear();
		history.push('/');
	};

	return (
		<Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
			<Container fluid className="px-0">
				<div className="d-flex justify-content-between w-100">
					<div className="d-flex align-items-center">
						{localStorage.getItem('userName')
							? `Username: ${localStorage.getItem('userName')}`
							: null}
					</div>
					<div className="d-flex align-items-center">
						{localStorage.getItem('userName')
							? `Service code: ${localStorage.getItem('serviceCode')}`
							: null}
					</div>
					<Nav className="align-items-center d-none d-md-block">
						<Button variant="white" className="m-1" onClick={handleLogout}>
							<FontAwesomeIcon
								icon={faSignOutAlt}
								className="text-danger me-2"
							/>{' '}
							Logout
						</Button>
					</Nav>
				</div>
			</Container>
		</Navbar>
	);
};
